import React, { Component } from 'react'
import { Collapse, Button } from 'react-bootstrap'
import EditLinkCard from './EditLinkCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/fontawesome-free-solid'

export default class LinkCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false
    }
  }

  render() {
    if(this.props.type === "link"){
      return (
        <div 
          key={this.props.element.uid} 
          className="linkContainer border_primary" 
          style={{zIndex:this.props.index}}
        >
          {this.props.public ?
            <div className="linkContainerText clickable">
              <a href={this.props.element.link}>
                <span className="linkText">
                  {this.props.element.name}
                </span>
              </a>
            </div>
          :
            <div>
              <Collapse in={!this.state.edit}>
                <div>
                  <div className="linkContainerText">
                    <span className="linkText">
                      {this.props.element.name}
                    </span>
                    <span className="editIcon">
                      <Button className="linkIcon" variant="" onClick={() => this.setState({edit: true})}>
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </Button>
                    </span>
                  </div>
                </div>
              </Collapse>
              <Collapse in={this.state.edit}>
                <div>
                  <EditLinkCard 
                    onClose={() => this.setState({edit: false})} 
                    shouldRender={this.state.edit} 
                    userObject={this.props.userObject} 
                    link={this.props.element}
                    showModal={this.props.showModal}
                  />
                </div>
              </Collapse>
            </div>
          }
        </div>
      )
    }
    return null
  }
}
