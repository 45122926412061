import imageCompression from 'browser-image-compression';
import { logEvent } from 'firebase/analytics';
import { analytics_terms } from '../constants/brand_constants';
import { analytics } from './firebase';

/**
 * Compressed image files down to under 1mb
 * @param {e.target.files[0]} imageFile 
 * @returns Promise
 */
export function compress(imageFile){
  const options = {
    maxSizeMB: .2,
    useWebWorker: true
  }
  return imageCompression(imageFile, options)
    .then((compressedFile) => {
      return compressedFile
    })
    .catch(error =>{
      logEvent(analytics, analytics_terms.imageCompressionFailed, {error: error})
      return null
    })
}