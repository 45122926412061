import React, { Component } from 'react'
import { Card, InputGroup, FormControl, Button, Form, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink,faFont } from '@fortawesome/fontawesome-free-solid'
import { functions } from '../../services/firebase'
import {uid} from 'uid'
import moment from 'moment'
import ErrorCard from './ErrorCard'
import { httpsCallable } from 'firebase/functions'

export default class AddLinkCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: "",
      hint: "",
      loading: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(form){
    form.preventDefault()
    this.setState({
      loading: true
    })
    const date = moment().format("MM-DD-YYYY hh:mm:dd")
    const linkObject = {
      type: 'link',
      name: form.target.name.value,
      link: form.target.link.value,
      uid: uid(),
      dateAdded: date
    }


    const addLinkFunction = httpsCallable(functions, "addLink")
    addLinkFunction({added: linkObject})
    .then((resp) => {
      this.setState({
        loading: false
      })
      this.props.onCancel() // Close drop down
      this.props.showModal("Added Link", "success")
      // Blank out form for next submission
      form.target.name.value = ""
      form.target.link.value = ""
    })
    .catch(error => {
      this.setState({
        error: error.message,
        loading: false,
      })
    }) 
  }

  render() {
    return (
      <div className="addLinkCardContainer">
        <Card className="addSection">
          <Card.Header>Add Link</Card.Header>
          <Form onSubmit={this.handleSubmit}> 
            <Card.Body>
                <Form.Group>
                  <Form.Text>
                    Link Name
                  </Form.Text>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      <FontAwesomeIcon icon={faFont} />
                    </InputGroup.Text>
                    <FormControl name="name" placeholder="Name " aria-label="name" aria-describedby="basic-addon1" />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Text muted>
                    Link
                  </Form.Text>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">
                      <FontAwesomeIcon icon={faLink} />
                    </InputGroup.Text>
                    <FormControl name="link" placeholder="Link" aria-label="link" aria-describedby="basic-addon1"  />
                  </InputGroup>
                </Form.Group>
                {this.state.error !== "" &&
                  <ErrorCard error={this.state.error} hint={this.state.hint}/>
                }
                <div className="buttonContainer">
                  <Button type="submit" variant="custom_primary" className="submitButton" style={{minWidth: "70px"}}>
                    {this.state.loading ?
                      <Spinner animation="border" size="sm" />
                    :
                      <>
                        Save
                      </>
                    }
                  </Button>
                  <Button onClick={this.props.onCancel} variant="custom_red">Close</Button>
                </div>
            </Card.Body>
          </Form>
        </Card>
      </div>
    )
  }
}
