import { cloneDeep } from 'lodash'
import React, { Component } from 'react'
import ReactSwitch from 'react-switch'
// https://www.npmjs.com/package/react-switch
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../../services/firebase'
/**
 * text
 * placeholder
 * value
 * private
 * databaseName
 */
export default class ToggleSettingsField extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: false
    }

    this.handelChange = this.handelChange.bind(this)
  }

  /**
   * live update the database
   * @param {bool} checked 
   */
  handelChange(checked){
    const currentValue = this.props.value
    // if changed, update database 
    if(checked !== currentValue) {
      if(this.props.private){
        let privateSettings = cloneDeep(this.props.privateSettings)
        privateSettings[this.props.databaseName] = checked
        this.setState({
          checked
        })
        updateDoc(doc(db, "profiles", this.props.profileObject.uid, "private", "settings"), privateSettings)
      } 

      // Not private
      else {
        let profileObject = cloneDeep(this.props.profileObject)
        profileObject[this.props.databaseName] = checked
        this.setState({
          checked
        })
        updateDoc(doc(db, "profiles", this.props.profileObject.uid), profileObject)
      }
    }
  }

  render() {
    return (
      <div className="toggleFieldContainer">
        <div className="textContainer">
          {this.props.text}
        </div>
        <div className="checkmarkContainer">
          <ReactSwitch 
            onChange={this.handelChange}
            checked={!!this.props.value}
            disabled={this.props.disabled}
            onColor={"#3B5999"}
          />
        </div>
      </div>
    )
  }
}
