import { Component } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import { 
  NavbarContainer, 
  LoginScreen, 
  ProfileScreen, 
  LandingPageScreen, 
  PublicProfileScreen, 
  RegisterScreen, 
  NotFoundScreen, 
  ProfileSettingsScreen
} from './screens';
import { auth } from './services/firebase'
import { 
  onAuthStateChanged,
  signOut
} from 'firebase/auth';
import {doc, onSnapshot, updateDoc } from "firebase/firestore"
import { db } from './services/firebase';
import "./styles/app.scss"
import { cloneDeep } from 'lodash';
import FirebaseActionHandler from './screens/FirebaseActionHandler';
import FeedbackScreen from './screens/FeedbackScreen';
import { Modal } from 'react-animation-library/dist/'

// eslint-disable-next-line import/no-anonymous-default-export
export default function(props) {
  const navigation = useNavigate();

  return <App {...props} navigation={navigation} />;
}

class App extends Component {
  constructor(){
    super()
    this.state = {
      authed: false,
      userID: null,
      userInfo: null,
      user: null,
      privateSettings: {},
      announcements: null,
      displayModal: false,
      modalMessage: "",
      modalVariant: ""
    }
    this.watchAuth = this.watchAuth.bind(this);
    this.onSignOut = this.onSignOut.bind(this);
    this.watchUser = this.watchUser.bind(this);
    this.watchPrivate = this.watchPrivate.bind(this);
    this.watchAnnouncements = this.watchAnnouncements.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    this.watchAuth()
  }

  watchAuth(){
    this.authListen = onAuthStateChanged(auth, user => {
      // check for user status
      if(user && this.state.authed === false) {
        this.setState({
          authed: true,
          userUID: user.uid,
          user: user
        })

        this.watchUser(user.uid, user)
        this.watchAnnouncements()
      } 
      
      else {
        this.onSignOut();
        this.setState({
          authed: false,
          userUID: null,
          user: null
        })
      }
    })
  }

  watchPrivate(userUID) {
    this.watchPrivateSettings = onSnapshot(doc(db, "profiles", userUID, "private", "settings"), doc => {
      this.setState({
        privateSettings: doc.data()
      })
    })
  }

  watchUser(userUID, user) {
    this.userListen = onSnapshot(doc(db, "profiles", userUID), userDoc => {
      this.setState({
        userInfo: userDoc.data()
      })
      if(userDoc.data()){
        this.watchPrivate(user.uid)
        if (userDoc.data().public === false && user.emailVerified) {
          let temp = cloneDeep(userDoc.data())
          temp.public = true
          updateDoc(doc(db, "profiles", user.uid), temp)
        }
      }
    })
  }

  watchAnnouncements() {
    this.announcementListen = onSnapshot(doc(db, "announcements", "profile_announcements"), an => {
      this.setState({
        announcements: an.data()
      })
    })
  }

  onSignOut() {
    signOut(auth)
    .then(() => {
      try {
        this.announcementListen()
        this.watchPrivateSettings()
        this.userListen()
        this.props.navigation('/nav/login')
      }
      finally {
        return 
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  componentWillUnmount(){
    // this.announcementListen()
    // this.watchPrivateSettings()
    // this.userListen()
    // this.authListen()
  }

  /**
 * 
 * @param {string} message any string, should keep short like "added" or "error"
 * @param {string} variant success, warning, danger, trash
 */
  showModal(message, variant){
    this.setState({
      displayModal: true,
      modalMessage: message, 
      modalVariant: variant
    })
  }


  closeModal(){
    this.setState({
      displayModal: false,
      modalMessage: "",
      modalVariant: ""
    })
  }
  

  render(){
    return (
      <div className="App">
        <Modal 
          show={this.state.displayModal}
          message={this.state.modalMessage}
          variant={this.state.modalVariant}
          onClose={this.closeModal}
          animated={true}
        />
        <Routes>
          <Route 
            path="/" 
            element={
                <NavbarContainer 
                  signOut={this.onSignOut} 
                  authed={this.state.authed}
                />
              }
            >
            <Route 
              path=":profileID" 
              element={<PublicProfileScreen 
                navigate={path => this.props.navigation(path)}
                authed={this.state.authed}
              />}
            />
            <Route path="/" element={<LandingPageScreen authed={this.state.authed} navigate={path => this.props.navigation(path)} />} />
            <Route path="nav">
              <Route path="firebase" 
                element={
                  <FirebaseActionHandler 
                    navigate={path => this.props.navigation(path)} 
                    currentUser={this.state.user} 
                  />
                } 
              />
              <Route path="login" 
                element={
                  <LoginScreen 
                    authed={this.state.authed} 
                    navigate={path => this.props.navigation(path)}
                  />
                } 
              />
              <Route path="register" 
                element={
                  <RegisterScreen 
                    authed={this.state.authed} 
                    navigate={path => this.props.navigation(path)} 
                  />
                }
              />
              <Route path="feedback" element={<FeedbackScreen authed={this.state.authed} navigate={path => this.props.navigation(path)}/>} />
              <Route 
                path="profile" 
                element={
                  <ProfileScreen 
                    navigate={path => this.props.navigation(path)} 
                    profileObject={this.state.userInfo}
                    privateSettings={this.state.privateSettings}
                    currentUser={this.state.user}
                    authed={this.state.authed}
                    announcements={this.state.announcements}
                    showModal={this.showModal}
                  />
                } 
              />
              <Route
                path="profile-settings"
                element={
                  <ProfileSettingsScreen
                    navigate={path => this.props.navigation(path)} 
                    profileObject={this.state.userInfo}
                    privateSettings={this.state.privateSettings}
                    logout={this.onSignOut}
                  />
                }
              />

              <Route 
                path="*"
                element={
                  <NotFoundScreen 
                    navigate={path => this.props.navigation(path)} 
                    authed={this.state.authed}
                  />
                } 
              />
            </Route>
          </Route>
        </Routes>
      </div>
    );
  }
}
