// this will display specific information regarding the profile
import React from 'react'
import { useParams } from 'react-router-dom'
import PublicProfileContainerScreen from './PublicProfileContainerScreen'

export default function PublicProfileScreen(props) {
  let params = useParams()

  return (
    <PublicProfileContainerScreen
      userName={params.profileID}
      navigate={path => props.navigate(path)}
      authed={props.authed}
    />
  )
}

