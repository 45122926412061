import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

export default class SettingsButton extends Component {
  render() {
    return (
      <div className="redButtonContainer">
        <Button variant={this.props.variant} onClick={this.props.onClick}>
          {this.props.text}
        </Button>
        {this.props.note &&
          <div className="note">
            {this.props.note}
          </div>
        }
      </div>
    )
  }
}
