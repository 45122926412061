import { faFacebook, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Container, Navbar, Nav, NavDropdown, Form } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import { brand_constants } from '../constants/brand_constants'
import '../styles/navbar.scss'

export default class NavbarContainer extends Component {
  onShare() {    
  }

  render() {
    return (
      <div>
        {/* Navbar */}
        <Navbar bg="light" expand="lg">
          <Container fluid>
            <Navbar.Brand href="/">
              <div className="brand_container">
                <img className="navbar_logo" src={brand_constants.long_logo_svg} alt="lnkable logo"/>
                <span>
                  Beta
                </span>
              </div>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll" className="navbarTextContainer">
              <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              </Nav>
              <Form className="d-flex navbarTextContainer">
                {this.props.authed ? 
                  <NavDropdown title="Profile" id="navbarScrollingDropdown" align={{ lg: 'end' }} className="authNavbarContainer">
                    <NavDropdown.Item href="/nav/profile">Profile</NavDropdown.Item>
                    <NavDropdown.Item href="#">Analytics (Coming Soon)</NavDropdown.Item>
                    <NavDropdown.Item href="/nav/profile-settings">Settings</NavDropdown.Item>
                    <NavDropdown.Item onClick={this.props.signOut} className="logoutContainer">Logout</NavDropdown.Item>
                  </NavDropdown>
                :
                  <div className="authNavbarContainer">
                    <Nav.Link href="/nav/login">Login</Nav.Link>
                    <Nav.Link className="registerContainer" href="/nav/register">
                      Register
                    </Nav.Link>
                  </div>
                }
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        
        {/* Content goes here */}
        <main className="content-container">
          <Outlet/>
        </main>

        {/* Footer */}
        <footer className="footer--pin text-center text-white footer">
          <div className="container p-4 pb-0">
            <section className="">
              <p className="d-flex justify-content-center align-items-center">
                {this.props.authed ?
                <>
                  <span className="me-1"> Thanks for using</span>
                  <a href="https://www.lnkable.com" className="text-white">LNKABLE!</a>
                </>
                :
                <>
                  <span className="me-3">Use our platform</span>
                  <button type="button" className="btn btn-outline-light btn-rounded">
                    Sign up for free!
                  </button>
                </>
                }
              </p>
            </section>
          </div>
          <div className="text-center p-3" style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
            <div className="footer-icons">
              <FontAwesomeIcon icon={faFacebook} size="lg"/>
              <FontAwesomeIcon icon={faInstagram} size="lg"/>
              <FontAwesomeIcon icon={faTwitter} size="lg"/>
              <FontAwesomeIcon icon={faLinkedin} size="lg"/>
            </div>
            
            © 2022 Copyright:&nbsp;
            <a className="text-white" href={brand_constants.link}>{brand_constants.brand_name}</a>
            <br></br>
            {this.props.authed &&
              <a className="text-white" href="/nav/feedback">
                Provide feedback!
              </a>
            }
          </div>
        </footer>
      </div>
    )
  }
}
