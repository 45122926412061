import { faCheckCircle, faExclamationCircle } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import '../../styles/toast.scss'


export default class ToastNote extends Component {
  getIcon(variant){
    switch(variant){
      default:
      case "success":
        return faCheckCircle
      case "danger":
        return faExclamationCircle
    }
  }

  getBG(variant){
    switch(variant){
      default:
      case "success":
        return "green"
      case "danger":
        return "#D44332"
    }
  }

  // TODO: this is inefficient, called three times for every popup
  componentDidUpdate(){
    if(this.props.show){
      setTimeout(() => {
        this.props.onClose()
      }, 5000);
    }
  }

  render(){
    if(this.props.title !== ""){
      return (
        <div className={`toastContainer ${this.props.show ? "slide-in" : "slide-out"}`}>
          <div className="custom-toast-header" style={{backgroundColor: this.getBG(this.props.variant)}}>
            <FontAwesomeIcon icon={this.getIcon(this.props.variant)} size="lg" />
          </div>
          <div className="custom-toast-body">
            <div>
              <strong>
                {this.props.title}
              </strong>
            </div>
            <div className="desc">
              {this.props.content}
            </div>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }
}