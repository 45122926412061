import React, { Component } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { db } from '../../services/firebase'
import { addDoc, collection } from 'firebase/firestore'
import ErrorCard from './ErrorCard'
import { errorMessages } from '../../constants/brand_constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/fontawesome-free-solid'


export default class ReportModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      error: errorMessages.noError,
      selection: null,
      loading: false,
      submitted: false
    }
    this.submitReport = this.submitReport.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  onChange(e){
    this.setState({
      selection: e.target.value
    })
  }

  submitReport(){
    if (this.state.selection !== null){
      const reportObject ={
        reportedUID: this.props.profileObject.uid,
        reportedUsername: this.props.profileObject.userName,
        reason: this.state.selection
      }
      this.setState({
        loading: true
      })
      addDoc(collection(db, "reports"), reportObject)
      .then(() => {
        this.setState({
          submitted: true,
          loading: false
        })
        setInterval(() => {
          this.props.onCancel()
        }, 2000)
      })
    }
  }

  render() {
    return (
      <div className="reportModal">
        <Modal show={this.props.show} centered className="reportModal">
          {this.state.loading &&
            <Modal.Body>
              <div className="reportSpinner">
                <Spinner animation={"border"} role="status" />
              </div>
            </Modal.Body>
          }
          {this.state.submitted &&
            <Modal.Body>
              <div className='SubmittedReport'>
                <FontAwesomeIcon icon={faCheckCircle} />
                <h3>
                  Submitted
                </h3>
              </div>
            </Modal.Body>
          }
          {this.state.loading === false && this.state.submitted === false &&
            <Modal.Body>
              <strong>
                Report @{this.props.profileObject.userName}
              </strong>
              <div onChange={this.onChange}>
                <ul>
                  <li>
                    <input type="radio" value="Nudity" name="type" />
                    <label htmlFor="type">Nudity</label>
                  </li>
                  <li>
                    <input type="radio" value="Violence" name="type" />
                    <label htmlFor="type">Violence</label>
                  </li>
                  <li>
                    <input type="radio" value="bullying" name="type" />
                    <label htmlFor="type">Bullying</label>
                  </li>
                  <li>
                    <input type="radio" value="spam" name="type" />
                    <label htmlFor="type">Spam</label>
                  </li>
                  <li>
                    <input type="radio" value="other" name="type" />
                    <label htmlFor="type">Other</label>
                  </li>
                </ul>
              </div>
              <ErrorCard 
                error={this.state.error}
              />
              <div className="buttonContainer">
                <Button variant="custom_primary" onClick={this.submitReport}>
                  Submit
                </Button>
                <Button variant="custom_third" onClick={this.props.onCancel}>
                  Cancel
                </Button>
              </div>
            </Modal.Body>
          }
        </Modal>
      </div>
    )
  }
}
