import React, { Component } from 'react'
import { Form, FloatingLabel, Button, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { auth} from '../services/firebase'
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'
import "../styles/authScreens.scss"
import { brand_constants, errorMessages } from '../constants/brand_constants'
import logo from "../assets/long_logo.svg"
import PasswordStrengthBar from 'react-password-strength-bar';
import { functions } from '../services/firebase'
import { httpsCallable } from 'firebase/functions'
import ErrorCard from './components/ErrorCard'

// TODO: no username upper case. => change to lowercase
export default class RegisterScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: "",
      userName: "",
      password: "",
      passwordStrength: 0,
      loading: false
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.addUserToDB = this.addUserToDB.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.setPasswordStrength = this.setPasswordStrength.bind(this);
  }

  async onFormSubmit(form) {
    form.preventDefault()
    
    // check if password and confirm password matches
    if(form.target.password.value !== form.target.confirmPassword.value){
      this.setState({
        error: errorMessages.passwordNoMatch
      })
      return
    }

    // check password strength
    else if(this.state.passwordStrength < 3){
      this.setState({
        error: errorMessages.weakPassword
      })
      return
    }

    const isUserNameTaken = httpsCallable(functions, "isUserNameTaken")
    try {
      await isUserNameTaken({userName: form.target.username.value})
    }
    catch (error) {
      this.setState({
        error: error.message
      })
      return
    }
    // add user to auth provider
    createUserWithEmailAndPassword(auth, form.target.email.value, form.target.password.value)
    .then((userCredential) => {
      const user = userCredential.user;
      this.addUserToDB(user.uid, form.target.email.value, form.target.username.value, form.target.name.value, user)
        .then(() => {
          this.props.navigate('nav/profile')
        })
    })

    .catch(error => {
      const errorCode = error.code;
      switch (errorCode){
        case "auth/missing-email":
        case "auth/invalid-email":
          this.setState({
            error: errorMessages.wrongEmail
          })
          break;
        case "auth/email-already-in-use":
          this.setState({
            error: errorMessages.emailInUse
          })
          break;
        case "auth/weak-password":
          this.setState({
            error: errorMessages.weakPassword
          })
          break
        default:
          this.setState({
            error: errorCode
          })
      }
    })
  }

  addUserToDB(userUID, email, username, name, user){
    const addUserFunction = httpsCallable(functions, "validateRegisterUser")

    const userInformation = {
      email: email,
      userName: username.toLowerCase(),
      name: name,
      avatarPicture: null,
      uid: userUID,
      coverPhoto: null,
      info: {},
      theme: "",
      bio: "",
      randomUnsplash: true,
      unsplashSearchTerm: "landscape",
      sensInformation: false,
      public: false
    }
    
    const userSettings = {
      twoFactor: false,
    }
    
    return addUserFunction({user: userInformation, private: userSettings })
      .then(res => {
        return sendEmailVerification(user)
      })
      .catch(error => {
        this.setState({
          error: error.message
        })
        return
      })
  }

  onFormChange(form){
    if(form.target.name === "username"){
      this.setState({
        userName: form.target.value,
      })
    }

    if(form.target.name === "password"){
      this.setState({
        password: form.target.value
      })
    }
  }

  /**
   * 
   * @param {int} strength 1-4, 4 being the strongest
   * @param {object} feedback: contains a message or error depending on the password
   */
  setPasswordStrength(strength, feedback){
    this.setState({
      passwordStrength: strength
    })
  }

  componentDidMount(){
    if(this.props.authed){
      this.props.navigate("/nav/profile")
    }
  }

  componentDidUpdate(){
    if(this.props.authed){
      this.props.navigate("/nav/profile")
    }
  }

  render() {
    return (
      <div className="auth_container">
        <title>Register | LNKABLE</title>
        <Card border="custom_primary">
          <Card.Header className="auth_header">
            Register
          </Card.Header>
          <Card.Body>
            <div className="auth_logo">
              <img src={logo} alt="lnkable logo"/>
            </div>
            <Form onSubmit={this.onFormSubmit} onChange={this.onFormChange}>
              <Form.Group>
                <FloatingLabel suggested="username" className="userNameInputContainer mb-3" controlId="floatingInputUsername" label="Username">
                  <Form.Control className="userNameInput" name="username" placeholder="Username" aria-label="Username" aria-describedby="usernameText"/>
                  {brand_constants.brand_name}.com/{this.state.userName}
                </FloatingLabel>
              </Form.Group>
              <Form.Group>
                <FloatingLabel suggested="name" controlId="floatingInputName" label="Name" className="mb-3">
                  <Form.Control name="name" type="name" placeholder="John Smith" />
                </FloatingLabel>
              </Form.Group> 
              <Form.Group>
                <FloatingLabel suggested="email" controlId="floatingInputEmail" label="Email address" className="mb-3">
                  <Form.Control name="email" type="email" placeholder="name@example.com" />
                </FloatingLabel>
              </Form.Group>  
              <Form.Group>
                <FloatingLabel controlId="floatingPassword" label="Password">
                  <Form.Control name="password" type="password" placeholder="Password" aria-describedby="passwordHelpBlock" autoComplete="on"/>
                </FloatingLabel>
                <PasswordStrengthBar className="mt-3" password={this.state.password} onChangeScore={this.setPasswordStrength}/>
              </Form.Group>
              <br/>
              <Form.Group>
                <FloatingLabel controlId="floatingConfirmPassword" label="Confirm Password">
                  <Form.Control name="confirmPassword" type="password" placeholder="Confirm Password" aria-describedby="confirmPasswordHelpBlock" autoComplete="on"/>
                </FloatingLabel>
              </Form.Group>
              <br/>
              <ErrorCard error={this.state.error} />
              <Button className="auth_button" variant="custom_primary" type="submit">
                Register
              </Button>
              <Form.Group>
                <Link to="/nav/login">Have an account?</Link>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
    )
  }
}
