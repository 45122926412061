/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth} from 'firebase/auth'
import { getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";

// NOTE: Comment out for prod 
import { getApp } from "firebase/app";
import { connectAuthEmulator } from 'firebase/auth'
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectStorageEmulator } from "firebase/storage";
import { connectFunctionsEmulator } from "firebase/functions"


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { getStorage } from "firebase/storage";
import {getFunctions } from "firebase/functions"

const firebaseConfig = {
  apiKey: "AIzaSyBf32P8y_iUsCGKt2L6vBDjRkeiLKf8TIw",
  authDomain: "hobbylink-dev.firebaseapp.com",
  projectId: "hobbylink-dev",
  storageBucket: "hobbylink-dev.appspot.com",
  messagingSenderId: "1083846542147",
  appId: "1:1083846542147:web:66ec410c1f6231fbdfa5f6",
  measurementId: "G-BCD3S328CX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth()
export const db = getFirestore()
export const storage = getStorage()
const perf = getPerformance(app);
export const functions = getFunctions(app)

// NOTE: THIS IS FOR EMULATOR
// export const functions_1 = getFunctions(getApp())
// connectFunctionsEmulator(functions_1, "127.0.0.1", 5001)
// connectFirestoreEmulator(db, "127.0.0.1", 8080)
// connectAuthEmulator(auth, "http://127.0.0.1:9099");
// connectStorageEmulator(storage, "127.0.0.1", 9199)
