import React, { Component } from 'react'
import { Button, Container } from 'react-bootstrap'
import "../styles/notFound.scss"

export default class NotFoundScreen extends Component {
  render() {
    return (
      <div className="notFoundContainer">
        <title>404 | LNKABLE</title>
        <div className="gradient-text">
          404
        </div>
        <Container className="content">
          Uh oh! Looks like you've stumbled upon an unknown page! Lets get you somewhere nice by clicking a button below.
        </Container>
        <div className="buttonContainer">
          <Button variant="custom_primary" onClick={() => this.props.navigate('/')}>
            Go Home
          </Button>
          {this.props.authed ?
            <>
              <Button 
                variant="outline-first" 
                onClick={() => this.props.navigate('/nav/profile')}
              >
                Go to your profile
              </Button>
            </>
            :
            <>
              <Button
                variant="outline-first" 
                onClick={() => this.props.navigate('/nav/register')}
              >
                Signup
              </Button>
            </>
          }
        </div>
      </div>
    )
  }
}
