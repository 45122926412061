import React, { Component } from 'react'
import SectionCard from './SectionCard'

export default class ListSectionCard extends Component {
  render() {
    if (this.props.userObject.info.hasOwnProperty("sections")) {
      return (
        <div className="allSectionContainer">
          {this.props.userObject.info.sections.map((element, index) => {
            return (
              <SectionCard 
                key={element.uid}
                element={element}
                public={this.props.public}
                userObject={this.props.userObject}
                index={10000 - index}
                showModal={this.props.showModal}
              />
            )
          })}
        </div>
      )
    }
    else {
      return <></>
    }
  }
}
