import React, { Component } from 'react'
import { Button, Card, Spinner } from 'react-bootstrap'
import "../styles/authScreens.scss"
import logo from "../assets/long_logo.svg"
import { applyActionCode } from 'firebase/auth'
import { auth } from '../services/firebase'
import { errorMessages } from '../constants/brand_constants'

export default class ConfirmEmailContainerScreen extends Component {
  constructor(props){
    super(props)
    this.state = {
      error: errorMessages.noError,
      success: false,
      loading: true
    }

    this.goToProfile = this.goToProfile.bind(this)
  }

  componentDidMount(){
    // artificial loading
    setTimeout(() => {
      applyActionCode(auth, this.props.code)
      .then(() => {
        this.setState({
          error: errorMessages.noError,
          success: true,
          loading: false
        })
        // wait 10 seconds before forwarding to profile
        setTimeout(() => {
          this.props.navigate("nav/profile")
        }, 10000);
      })
      .catch(error => {
        if(this.props.currentUser.emailVerified){
          this.setState({
            error: errorMessages.alreadyVerified,
            loading: false,
            success: true
          })
          // wait 10 seconds before forwarding to profile
          setTimeout(() => {
            this.props.navigate("nav/profile")
          }, 10000);
          return
        }
        switch(error.code){
          default:
          case "auth/invalid-action-code":
            this.setState({
              error: errorMessages.invalidActionCode,
              success: false,
              loading: false
            })
            break
        }
      })
    }, 1000);
  }

  goToProfile(){
    if(this.props.currentUser){
      this.props.navigate("nav/profile")
      return
    }
    this.props.navigate("nav/login")
  }

  render() {
    return (
      <div className="auth_container">
        <Card>
          <Card.Header className="auth_header">
            Email Confirmed!
          </Card.Header>
          <Card.Body>
            <div className="auth_logo">
              <img src={logo} alt="lnkable logo"/>
            </div>
            {this.state.loading ?
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            :
            <>
              {this.state.error ?
                <div>
                  {this.state.error}
                </div>
              :
                <div>
                  Your email has been verified! Forwarding you to your profile...
                  <Button className="mt-3" onClick={this.goToProfile}>
                    Go to Profile
                  </Button>
                </div>
              }
            </>
            }
          </Card.Body>
        </Card>

      </div>
    )
  }
}
