import { faTrash } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { doc, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import React, { Component } from 'react'
import { Button, Card, Form, Spinner } from 'react-bootstrap'
import { db, functions } from '../../services/firebase'
import ErrorCard from './ErrorCard'

export default class EditLinkCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      link: "",
      error: "",
      loading: false,
      deleteLoading: false
    }

    this.updateName = this.updateName.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.deleteLink = this.deleteLink.bind(this)
  }

  componentDidMount() {
    this.setState({
      name: this.props.link.name,
      link: this.props.link.link
    })
  }

  updateName(value, type){
    if(type === "name") {
      this.setState({
        name: value.target.value
      })
    }
    else if (type === "link") {
      this.setState({
        link: value.target.value
      })
    }
  }

  handleSubmit(form) {
    form.preventDefault()
    this.setState({
      loading: true
    })
    const date = moment().format("MM-DD-YYYY hh:mm:ss")
    const linkObject = {
      dateAdded: date,
      link: this.state.link,
      name: this.state.name,
      type: "link",
      uid: this.props.link.uid
    }

    const editLinkFunction = httpsCallable(functions, "editLink")
    editLinkFunction({edited: linkObject})
    .then(() => {
      this.setState({
        loading: false
      })
      this.props.onClose()
      this.props.showModal("Edited Link", "success")
    })
    .catch(error => {
      this.setState({
        error: error.message,
        loading: false
      })
    })
  }

  deleteLink(){
    this.setState({
      deleteLoading: true
    })
    let profileObject = cloneDeep(this.props.userObject)
    let index = profileObject.info.links.map(x => {
      return x.uid;
    }).indexOf(this.props.link.uid);
    
    profileObject.info.links.splice(index, 1);
    updateDoc(doc(db, "profiles", this.props.userObject.uid), profileObject)
    .then(() => {
      this.setState({
        deleteLoading: false
      })
      this.props.showModal("Deleted Link", "trash")
    })
  }

  render() {
    return (
      <Card border="custom_none" className="mt-3 editLinkContainer">
        <Card.Body>
          <h5>Edit</h5>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-1" controlId="name">
              <Form.Text muted>
                Name
              </Form.Text>
              <Form.Control required type="name" onChange={(val) => this.updateName(val, "name")} value={this.state.name} placeholder={this.state.name} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="link">
              <Form.Text muted>
                Link
              </Form.Text>
              <Form.Control required type="link" onChange={(val) => this.updateName(val, "link")} value={this.state.link} placeholder={this.state.link} />
            </Form.Group>
            <ErrorCard error={this.state.error} hint={this.state.hint}/>
            <div className="buttonContainer">
              <Button type="submit" variant="custom_primary">
                {this.state.loading ?
                  <Spinner animation="border" size="sm" />
                :
                  <>
                    Edit
                  </>
                }
              </Button>
              <Button onClick={this.props.onClose} variant="custom_red">
                Cancel
              </Button>
              <Button onClick={this.deleteLink} variant="custom_red">
                {this.state.deleteLoading ?
                  <Spinner animation="border" size="sm" />
                :
                  <FontAwesomeIcon icon={faTrash} style={{color: "white"}}/>
                }
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    )
  }
}