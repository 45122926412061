import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/fontawesome-free-solid'

export default class AddButton extends Component {
  render() {
    if(this.props.shouldRender){
      return (
        <div className="sectionContainer addButtonContainer" onClick={this.props.clicked}>
          <FontAwesomeIcon icon={faPlus} />
            <span style={{paddingLeft: "10px"}}>
              {this.props.title}
            </span>
        </div>
      )
    } else return <></>
  }
}
