import React, { Component } from 'react'
import "../styles/landingPageScreen.scss"
import look_at_phone from "../assets/mocks/look_at_phone.jpeg"
import { Button} from 'react-bootstrap'

import customize from "../assets/mocks/customize.png"
import withSocialMedia from "../assets/mocks/with_social_media.png"
import sections from '../assets/mocks/sections.png'

export default class LandingPageScreen extends Component {
  render() {
    return (
      <div className="landingPage">
        <title>LNKABLE</title>
        <div className="coverPhotoContainer">
          <div className="coverPhotoOverlay">
            <div className="coverPhotoContent">
              <h1>
                A one-link solutions for hobbyist!
              </h1>
              <h4>
                Connect your social media to LNKABLE for an all-in-one sharable solution
              </h4>
              <Button variant="custom_second" onClick={() => {this.props.navigate("/nav/register")}}>
                Get started!
              </Button>
            </div>
          </div>
          <img className="coverPhoto" src={look_at_phone} alt="coverphoto to lnkable" />
        </div>

        <div className="section rev">
          <div className="sectionInfo">
            <h1>Sections!</h1>
            <h5>
              Separate your hobbies into sections to show more organized content!
            </h5>
          </div>
          <img className="bigger_image floating-spin" src={sections} alt="customize your profile at lnkable" />
        </div>
        
        <div className="section reg">
          <img className="floating" src={withSocialMedia} alt="customize your profile at lnkable" />
          <div className="sectionInfo">
            <h1>Add your social media accounts</h1>
            <h5>
              Add your social media accounts plus unlimited custom links 
              and sections to your profile!
            </h5>
          </div>
        </div>
        
        <div className="section rev">
          <div className="sectionInfo">
            <h1>Customize and Sectionize!</h1>
            <h5>
              lnkable's profiles maintain a professional look while allowing you to add 
              social media, custom links and sections.
            </h5>
          </div>
          <img src={customize} className="floating-scale" alt="customize your profile at lnkable" />
        </div>

        <div className="section-whole">
          <div className="sectionInfo">
            <h1>Join LNKABLE Today!</h1>
            <h5>
              Join LNKABLE as we set off to create a product made by 
              <strong> hobbyists</strong> for <strong> hobbyists</strong>
            </h5>
            <Button variant="custom_primary" onClick={() => this.props.navigate('/nav/register')}>
              Join Now!
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
