import React, { Component } from 'react'
import { Form, FloatingLabel, Button, Card, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { analytics, auth } from '../services/firebase'
import { analytics_terms, errorMessages } from '../constants/brand_constants'
import "../styles/authScreens.scss"
import logo from '../assets/long_logo.svg'
import { logEvent } from 'firebase/analytics'
// import { functions } from '../services/firebase'
// import { httpsCallable } from 'firebase/functions'

export default class LoginScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errorMessage: null,
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  onFormSubmit(form){
    form.preventDefault()

    signInWithEmailAndPassword(auth, form.target.email.value, form.target.password.value)
    .then(() => {
      logEvent(analytics, analytics_terms.login, {email: form.target.email.value})
      this.props.navigate('nav/profile')
    })
    .catch(error => {
      logEvent(analytics, analytics_terms.failedLogin, {code: error.code, message: error.message, email: form.target.email.value})
      const errorCode = error.code;
      switch (errorCode){
        case "auth/missing-email":
        case "auth/invalid-email":
        case "auth/user-not-found":
          this.setState({
            errorMessage: errorMessages.wrongEmail
          })
          break;
        case "auth/wrong-password":
          this.setState({
            errorMessage: errorMessages.invalidPassword
          })
          break;
        case "auth/weak-password":
          this.setState({
            errorMessage: errorMessages.weakPassword
          })
          break
        case "auth/internal-error":
          this.setState({
            errorMessage: errorMessages.unknown
          })
          break
        default:
          this.setState({
            errorMessage: errorCode
          })
      }
    })
  }

  componentDidMount(){
    if(this.props.authed){
      this.props.navigate("/nav/profile")
    }
  }

  componentDidUpdate(){
    if(this.props.authed){
      this.props.navigate("/nav/profile")
    }
  }

  render() {
    return (
      <div className="auth_container">
        <title>Login | LNKABLE</title>
        <Card border="custom_primary">
          <Card.Header className="auth_header">
            Welcome Back!
          </Card.Header>
          <Card.Body>
            <div className="auth_logo">
              <img src={logo} alt="lnkable logo"/>
            </div>
            <Form onSubmit={this.onFormSubmit}>
              <Form.Group>
                <FloatingLabel className="mb-3" controlId="floatingInputLogin" label="Email address" >
                  <Form.Control required name="email" type="email" placeholder="name@example.com" />
                </FloatingLabel>
              </Form.Group>
              <Form.Group>
                <FloatingLabel className="mb-3" controlId="floatingPassword" label="Password">
                  <Form.Control  name="password" type="password" placeholder="Password" aria-describedby="passwordHelpBlock" autoComplete="on"/>
                </FloatingLabel>
              </Form.Group>
              {this.state.errorMessage &&
                <Container className="errorContainer">
                  <p>
                    {this.state.errorMessage}
                  </p>
                </Container>
              }
              <Button className="auth_button" variant="custom_primary" type="submit">Login</Button>
              <Form.Group>
                <Link to="/nav/register">
                  Don't have an account?
                </Link>
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
      </div>
    )
  }
}
