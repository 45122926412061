import React, { Component } from 'react'
import SubSectionCardIndividual from './SubSectionCardIndividual'

export default class SubSectionCard extends Component {
  render() {
    return (
      <>
        {this.props.subSection.info.map(sectionInfo => {
          return (
            <SubSectionCardIndividual
              key={sectionInfo.uid}
              sectionInfo={sectionInfo}
              public={this.props.public}
              userObject={this.props.userObject}
              section={this.props.section}
              showModal={this.props.showModal}
            />
          )
        })}
      </>
    )
  }
}
