import React, { Component } from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import AddSectionInfo from './AddSectionInfo'
import SubSectionCard from './SubSectionCard'
import { cloneDeep } from 'lodash'
import { updateDoc, doc } from 'firebase/firestore'
import { db } from '../../services/firebase'
import EditSection from './EditSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid'

export default class SectionCard extends Component {
  constructor(props){
    super(props)

    this.state = {
      edit: false,
      addSectionInfo: false,
    }
    this.deleteSection = this.deleteSection.bind(this);
    this.clicked = this.clicked.bind(this)
  }

  deleteSection(element){
    let profileObject = cloneDeep(this.props.userObject)
    let index = profileObject.info.sections.map(x => {
      return x.uid
    }).indexOf(element.uid)

    profileObject.info.sections.splice(index, 1)
    updateDoc(doc(db, "profiles", this.props.userObject.uid), profileObject)
    .then(() => {
      this.props.showModal("Deleted Section", "trash")
    })
  }

  clicked(v){
    this.setState({
      addSectionInfo: v
    })
  }

  render() {
    return (
      <div className="sectionContainer" style={{zIndex:this.props.index}}>
        <div className="linkContainerText">
          <div className="sectionTitle">
            {this.props.element.name}
          </div>
          {!this.props.public &&
            <span className="editIcon sectionIconContainer">
              <DropdownButton 
                variant="outline-none"
                title={
                  <FontAwesomeIcon 
                    className="sectionIcon" 
                    icon={faPencilAlt} 
                  />
                } 
                id="input-group-dropdown-1"
              >
                <Dropdown.Item onClick={() => this.clicked(true)}>Add Section Information</Dropdown.Item>
                <Dropdown.Item onClick={() => this.setState({edit: true})}>Edit</Dropdown.Item>
                <Dropdown.Item className="deleteText" onClick={() => this.deleteSection(this.props.element)}>Delete</Dropdown.Item>
              </DropdownButton>
            </span>
          }
        </div>
        {!this.props.public &&
          <div className="sectionAddEditContainer">
            <EditSection 
              shouldRender={this.state.edit} 
              element={this.props.element}
              onClose={() => this.setState({edit: false})}
              userObject={this.props.userObject}
              showModal={this.props.showModal}
            />
            <AddSectionInfo 
              shouldRender={this.state.addSectionInfo} 
              onCancel={() => this.clicked(false)} 
              section={this.props.element} 
              userObject={this.props.userObject}
              showModal={this.props.showModal}
            />
          </div>
        }

        <SubSectionCard 
          subSection={this.props.element} 
          public={this.props.public} 
          section={this.props.element}
          userObject={this.props.userObject}
          showModal={this.props.showModal}
        />
      </div>
    )
  }
}
