import React, { Component } from 'react'
import AddLinkCard from './AddLinkCard';
import AddSectionCard from './AddSectionCard';
import AddButton from './AddButton';
import AddSocialMediaCard from './AddSocialMediaCard';
import ListLinkCard from './ListLinkCard';
import ListSectionCard from './ListSectionCard';
import { Collapse } from 'react-bootstrap';

export default class LinkList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addSection: false,
      addLink: false,
      addSectionInfo: false,
      addSocialMedia: false,
      socialMedia: "Social Media",
    }
  }

  render() {
    return (
      <div className="linkListContainer">
        {/* Social Media Links/Icons */}
        {!this.props.public &&
          <>
            <AddButton 
              shouldRender={true} 
              clicked={() => this.setState({addSocialMedia: true})} 
              title="Add/Edit Social Media"
            />
            <Collapse in={this.state.addSocialMedia}>
              <div>
                <AddSocialMediaCard 
                  shouldRender={this.state.addSocialMedia} 
                  onCancel={() => this.setState({addSocialMedia: false})} 
                  userObject={this.props.userObject}
                  showModal={this.props.showModal}
                />
              </div>
            </Collapse>

            <AddButton
              shouldRender={true} 
              clicked={() => this.setState({addLink: true})} 
              title="Add Link"
            />
            <Collapse in={this.state.addLink}>
              <div>
                <AddLinkCard
                  shouldRender={this.state.addLink} 
                  onCancel={() => this.setState({addLink: false})} 
                  userObject={this.props.userObject}
                  showModal={this.props.showModal}
                /> 
              </div>
            </Collapse>

            <AddButton
              shouldRender={true} 
              clicked={() => this.setState({addSection: true})} 
              title="Add Section"
            />
            <Collapse in={this.state.addSection}>
              <div>
                <AddSectionCard
                  shouldRender={this.state.addSection} 
                  onCancel={() => this.setState({addSection: false})} 
                  userObject={this.props.userObject}
                  showModal={this.props.showModal}
                  closeModal={this.props.closeModal}
                />
              </div>
            </Collapse>
          </>
        }

        <ListLinkCard 
          userObject={this.props.userObject} 
          public={this.props.public}
          showModal={this.props.showModal}
        />
        <ListSectionCard 
          userObject={this.props.userObject} 
          public={this.props.public}
          showModal={this.props.showModal}
        />
      </div>
    )
  }
}
