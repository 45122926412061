import React, { Component } from 'react'
import { collection, query, where, getDocs } from "firebase/firestore";
import { analytics, db } from '../services/firebase'
import CoverPhoto from './components/CoverPhoto';
import ProfileImage from './components/ProfileImage';
import SocialMediaList from './components/SocialMediaList'
import LinkList from './components/LinkList';
import SensContentSplash from './components/SensContentSplash';
import { logEvent } from 'firebase/analytics';
import { analytics_terms } from '../constants/brand_constants';
import { Spinner } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import logo from "../assets/tall_logo.png"
import NotPublic from './components/NotPublic';
import ReportModal from './components/ReportModal';

export default class PublicProfileContainerScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profileInfo: null,
      dismissed: false,
      appear: false,
      loading: true,
      noResult: false,
      reported: false
    }

    this.getProfileInfo = this.getProfileInfo.bind(this);
    this.reported = this.reported.bind(this)
  }

  componentDidMount(){
    this.getProfileInfo()
  }

  getProfileInfo(){
    // get info from database
    const q = query(collection(db, "profiles"), where("userName", "==", this.props.userName));
    getDocs(q)
      .then(docs => {
        this.setState({
          loading: true
        })

        let temp = null
        docs.forEach((doc) => {
          temp = doc.data()
          this.setState({
            profileInfo: doc.data(),
            appear: true
          })
        })
        return temp
      })
      .then((info) => {
        if(info === null){
          this.props.navigate("/nav/404")
          return
        }
        this.setState({
          loading: false
        })
      })
      .catch(error => {
        logEvent(analytics, analytics_terms.failedPublic, {error: error})
      })
  }

  reported(){
    this.setState({
      reported: true
    })
  }

  render() {
    if(this.state.loading){
      return (
        <div className="loadingContainer">
          <Spinner animation='border'/>
          <div className="loadingText">
            Loading...
          </div>
        </div>
      )
    }

    if(!this.state.profileInfo.public){
      return (
        <NotPublic clicked={() => this.props.navigate("/")} />
      )
    }

    return (
      <div className="profileScreenContainer">
        <Helmet>
          <title>{this.state.profileInfo.userName} | LNKABLE</title>
          <meta property="og:title" content={`@${this.state.profileInfo.userName} | LNKABLE`} />
          <meta property="og:description" content={`Checkout ${this.state.profileInfo.name}'s Profile on LNKABLE.com`} />
          <meta property="og:image" content={this.state.profileInfo.avatarPicture ? this.state.profileInfo.avatarPicture : logo} />
          <meta name="description" content={`View ${this.state.profileInfo.name}'s LNKABLE page!`}/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image:src" content={this.state.profileInfo.avatarPicture ? this.state.profileInfo.avatarPicture : logo }/>
        </Helmet>
        {this.state.profileInfo.sensInformation &&
          <SensContentSplash
            show={!this.state.dismissed} 
            dismissed={() => this.setState({dismissed: true})}
            declined={() => this.props.navigate('/')}
          />
        }
        <ReportModal show={this.state.reported} profileObject={this.state.profileInfo} onCancel={() => this.setState({reported: false})}/>
        <CoverPhoto 
          imagePath={this.state.profileInfo.coverPhoto} 
          unsplash={this.state.profileInfo.randomUnsplash}
          unsplashTerm={this.state.profileInfo.unsplashSearchTerm}
          public={true}
          reported={this.reported}
        />
        <ProfileImage imagePath={this.state.profileInfo.avatarPicture} />

        <div className="nameContainer">
          <div className="usernameContainer">
              @{this.state.profileInfo.userName}
          </div>
          <div className="realNameContainer">
            {this.state.profileInfo.name}
          </div>
          <div>
            {this.state.profileInfo.bio}
          </div>
        </div>
        <div className="socialMediaContainer">
          {this.state.profileInfo.info &&
            <SocialMediaList userObject={this.state.profileInfo}/>
          }
        </div>
        <div className="infoContainer">
          {this.state.profileInfo.info &&
            <LinkList userObject={this.state.profileInfo} public={true}/>
          }
        </div>
      </div>
    )
  }
}
