import React, { Component } from 'react'
import { Placeholder } from 'react-bootstrap'
import { random_unsplash_image_url } from '../../constants/brand_constants'

export default class CoverPhoto extends Component {
  constructor(props){
    super(props)
    this.state = {
      loaded: false
    }
  }

  getUnsplashPhoto(){
    return random_unsplash_image_url + "?" + this.props.unsplashTerm
  }

  render() {
    return (
      <>
        <div className="coverPhotoContainer">
          {this.props.imagePath !== null ?
            <>
              {!this.state.loaded &&
                <Placeholder as="p" animation="glow" className="skel_cover">
                  <Placeholder xs={12} />
                </Placeholder> 
              }
              <img
                src={this.props.imagePath} 
                alt=""
                className="coverPhoto" 
                onLoad={() => this.setState({loaded: true})}
              >
              </img>
            </>
          :
            <>
              {this.props.unsplash ?
                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                <img 
                  className="coverPhoto" 
                  src={this.getUnsplashPhoto()} 
                  alt="unsplash random image hosted on likable" 
                />
              :
                <div className="solidCoverPhoto" />
              }
            </>
          }
        </div>
      </>
    )
  }
}
