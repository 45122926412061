import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { social_media_list } from '../../constants/brand_constants';
import { faLink } from '@fortawesome/fontawesome-free-solid';

export default class SocialMediaList extends Component {
  getIcon(social){
    let icon = <FontAwesomeIcon icon = {faLink} />
    social_media_list.forEach(sm => {
      if(sm.value === social) {
        icon = <FontAwesomeIcon icon={sm.icon} className="socialMediaListIcon"/>
      }
    })
    return icon
  }

  render() {
    if(!this.props.userObject.info.hasOwnProperty("socialMedia")){
      return null
    }

    return (
      <div className="socialMediaListContainer">
        {this.props.userObject.info.hasOwnProperty("socialMedia") &&
          <>
            {this.props.userObject.info.socialMedia.map((link, index) => {
              return (
                <a href={link.link} key={index}>
                  {this.getIcon(link.value)}
                </a>
              )
            })}
          </>
        }
      </div>
    )
  }
}
