import React, { Component } from 'react'
import { Button } from 'react-bootstrap'

export default class NotPublic extends Component {
  render() {
    return (
      <div className="notFoundContainerProfile">
        <title>LNKABLE</title>
        <div className="title">
          Uh Oh!
        </div>
        <div className="notFoundContent">
          This profile is not public
        </div>

        <Button variant="custom_primary" onClick={this.props.clicked}>
          Go Home
        </Button>
      </div>
    )
  }
}
