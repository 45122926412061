import { logEvent } from 'firebase/analytics'
import { confirmPasswordReset } from 'firebase/auth'
import React, { Component } from 'react'
import { Button,Form, FloatingLabel, Card, Container} from 'react-bootstrap'
import logo from "../assets/long_logo.svg"
import { analytics_terms, errorMessages } from '../constants/brand_constants'
import { analytics, auth } from '../services/firebase'
import "../styles/authScreens.scss"

export default class PasswordResetContainerScreen extends Component {
  constructor(props){
    super(props)
    this.state = {
      error: errorMessages.noError
    }
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(form){
    form.preventDefault()
    if(form.target.password.value !== form.target.confirmPassword.value){
      this.setState({
        error: errorMessages.passwordNoMatch
      })
    } else {
      this.setState({
        error: errorMessages.noError
      })

      confirmPasswordReset(auth, this.props.code, form.target.password.value)
      .then(() => {
        if(this.props.currentUser !== null) {
          this.props.navigate("nav/profile")
        } else {
          this.props.navigate("nav/login")
        }
      })
      .catch(error => {
        logEvent(analytics, analytics_terms.failedPasswordReset, {error: error})
      })
    }
  }

  render() {
    return (
      <div className="auth_container">
        <Card border="custom_primary">
          <Card.Header className="auth_header">
            Reset Password
          </Card.Header>
          <Card.Body>
            <div className="auth_logo">
              <img src={logo} alt="lnkable logo"/>
            </div>
            <Form onSubmit={this.onSubmit}>
              <Form.Group>
                <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
                  <Form.Control name="password" type="password" placeholder="Password" aria-describedby="passwordHelpBlock" autoComplete="on"/>
                </FloatingLabel>
              </Form.Group>
              <Form.Group>
                <FloatingLabel controlId="floatingConfirmPassword" label="Password" className="mb-3">
                  <Form.Control name="confirmPassword" type="password" placeholder="Confirm Password" aria-describedby="confirmPasswordBlock" autoComplete="off"/>
                </FloatingLabel>
              </Form.Group>
              {this.state.error &&
                <Container className="errorContainer">
                  <p>
                    {this.state.error}
                  </p>
                </Container>
              }
              <Button className="auth_button" variant="custom_primary" type="submit">
                Submit
              </Button>
            </Form>
          </Card.Body>
        </Card>

      </div>
    )
  }
}
