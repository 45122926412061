/* eslint-disable no-useless-escape */
import { faDiscord, faEtsy, faFacebook, faGithub, faInstagram, faLinkedin, faPinterest, faSnapchat, faSoundcloud, faSpotify, faTiktok, faTwitch, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import logo_svg from '../assets/logo.svg'
import logo_png from '../assets/logo.png'
import long_logo_png from '../assets/long_logo.png'
import long_logo_svg from '../assets/long_logo.svg'
import tall_logo_png from '../assets/tall_logo.png'
import tall_logo_svg from '../assets/tall_logo.svg'

// TODO: this should be figured out how to change to local host
function getLink(){
  if(process.env.NODE_ENV === "production"){
    return "https://www.lnkable.com/"
  }
  else if (process.env.NODE_ENV === "development") {
    return "https://www.lnkable.com/"
  }
}

export const brand_constants = {
  "logo_svg": logo_svg,
  "logo_png": logo_png,
  "long_logo_png": long_logo_png,
  "long_logo_svg": long_logo_svg,
  "tall_logo_png": tall_logo_png,
  "tall_logo_svg": tall_logo_svg,
  "brand_name": "LNKABLE",
  "link": "/",
  "root_address": "https://lnkable.com",
  "copy_link": getLink()
}

export const image_size_constraint = 5000 // 5mb
export const random_unsplash_image_url = "https://source.unsplash.com/random/"
export const number_of_social_media_links_limit = 5
export const number_of_link_limit = 20;
export const number_of_section_limit=20;
export const number_of_subsection_limit=20;
export const website_regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
export const coverPhoto_regex = /coverPhoto.[A-z]*/
export const profilePicture_regex = /profilePicture.[A-z]*/

export const social_media_list = [
  {
    "name": "Facebook",
    "value": "facebook",
    "icon": faFacebook
  },
  {
    "name": "Twitter",
    "value": "twitter",
    "icon": faTwitter
  },
  {
    "name": "Instagram",
    "value": "instagram",
    "icon": faInstagram
  },
  {
    "name": "Linkedin",
    "value": "linkedin",
    "icon": faLinkedin
  },
  {
    "name": "TikTok",
    "value": "tiktok",
    "icon": faTiktok
  },
  {
    "name": "Snapchat",
    "value": "snapchat",
    "icon": faSnapchat
  },
  {
    "name": "Discord",
    "value": "discord",
    "icon": faDiscord
  },
  {
    "name": "Youtube",
    "value": "youtube",
    "icon": faYoutube
  },
  {
    "name": "Twitch",
    "value": "twitch",
    "icon": faTwitch
  },
  {
    "name": "Pinterest",
    "value": "pinterest",
    "icon": faPinterest
  },
  {
    "name": "Etsy",
    "value": "etsy",
    "icon": faEtsy
  },
  {
    "name": "Spotify",
    "value": "spotify",
    "icon": faSpotify
  },
  {
    "name": "SoundCloud",
    "value": "soundcloud",
    "icon": faSoundcloud
  },
  {
    "name": "Github",
    "value": "github",
    "icon": faGithub
  }
]

export const errorMessages = {
  "noError": "",
  "noLink": "Please input a link",
  "invalidLink": {
    "error": "Not a valid link",
    "hint": "Make sure your link starts with 'http:' or 'https://'"
  },
  "noName": "Please input a name",
  "noSocialPlatform": "Please select a social media platform",
  "dupSocialMedia": "Cannot have duplicate social media links",
  "noDescription": "",
  "noLinkName":{
    "error":"Please name your link.",
    "hint":"An example would be 'purchase here'",
  },
  "noTitle": "Please add a title",
  "wrongEmail": "Invalid Email",
  "weakPassword": "Password is too weak. Please try to use capital letters, numbers and special characters",
  "invalidPassword": "Invalid password",
  "noUserName": "Invalid username",
  "noPersonName": "Invalid name",
  "unknown": "We are experiencing a large amount of traffic. Please try again later.",
  "userNameNotFree": "Username is not available",
  "userNameSpecialChar": "Username cannot contain special characters",
  "selectSocialMedia": "Please Select a social media",
  "tooManySocialMedia": "Social media link limit has been reached. To add another, please remove a social media link.",
  "invalidUsername": "Invalid Username",
  "takenUserName": "Username has already been taken",
  "emptyField": "Field cannot be empty",
  "passwordNoMatch": "Passwords do not match",
  "invalidActionCode": "There was an issue verifying your email. Please try again later.",
  "alreadyVerified": "Your email is already verified. Forwarding you to your profile...",
  "emailInUse": "Email is already in use",
  "usernameTooLong": "Username is too long",
  "tooManyLinks": "you have reached the limit of links",
  "tooManySections": "You have reached the limit of sections",
  "tooManySubSections": "You have reached the limit of sub-sections",
}

export const toastMessages = {
  "link": {
    "update": {
      "success": {
        "content":"Successfully updated link",
        "title": "Updated Link"
      }
    }
  }
}

// returns true if username is invalid
/**
 * 
 * @param {string} username 
 * @returns 
 */
export function validateUsername(username){
  if (reservedWords.includes(username)){
    return [true, errorMessages.userNameNotFree]
  }
  if (username.length > 32){
    return [true, errorMessages.usernameTooLong]
  }
  const specialChars = /[` !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  if(specialChars.test(username)){
    return [true, errorMessages.userNameSpecialChar]
  }

  return [false, errorMessages.noError]
}

export const alertMessages = {
  "confirmEmail": "Your email has not yet been confirmed. Please confirm your email to publish your site",
  "confirmEmailAction": "Send email"
}

export const analytics_terms = {
  "login": "LOGIN",
  "logout": "LOGOUT",
  "failedLogin": "FAILED_LOGIN",
  "register": "REGISTER",
  "failedRegister": "FAILED_REGISTER",
  "createdUserDB": "CREATED_USER_DATABASE_COLLECTION",
  "failedCreatedUserDB": "FAILED_CREATED_USER_DATABASE_COLLECTION",
  "failedCreatedUserDBPrivate": "FAILED_CREATED_USER_DATABASE_COLLECTION_PRIVATE_SETTINGS",
  "failedImageUpload": "FAILED_IMAGE_UPLOAD",
  "failedEmailVerification": "FAILED_EMAIL_VERIFICATION",
  "imageCompressionFailed": "FAILED_IMAGE_COMPRESSION",
  "imageDeleteFailed": "FAILED_IMAGE_DELETE",
  "invalidType": "INVALID_TYPE",
  "failedPublic": "PUBLIC_PROFILE_LOAD_FAILURE",
  "failedSubmitFeedBack": "FAILED_FEED_BACK",
  "failedPasswordReset": "FAILED_PASSWORD_RESET",
  "deleteOldPhotoFail": "FAILED_DELETE_OLD_PHOTO"
}

export const reservedWords = [
  "nav",
  "profile",
  "settings",
  "analytics",
  "about",
  "feedback",
]