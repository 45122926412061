import { faExclamationCircle } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { Collapse } from 'react-bootstrap'

export default class ErrorCard extends Component {
  render() {
    return (
      <Collapse in={this.props.error !== ""}>
        <div>
          <div className="errorCard">
            <div className="errorCardContainer">
              <div className="iconLeftCol">
                <FontAwesomeIcon size="5x" icon={faExclamationCircle} />
              </div>
              <div className="rightContainer">
                <div className="errorText">
                  {this.props.error}
                </div>
                {this.props.hint !== undefined &&
                  <>
                    {this.props.hint !== "" &&
                      <div className="hint">
                        Hint: {this.props.hint}
                      </div>
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    )
  }
}
