import React, { Component } from 'react'
import LinkCard from './LinkCard'

export default class ListLinkCard extends Component {
  render() {
    if(this.props.userObject.info.hasOwnProperty("links")){
      return (
        <>
          {this.props.userObject.info.links.map((element, index) => {
            return (
              <LinkCard 
                key={element.uid} 
                type={element.type} 
                element={element} 
                userObject={this.props.userObject}
                public={this.props.public}
                index={10000 - index}
                showModal={this.props.showModal}
              />
            )
          })}
        </>
      )
    } else {
      return null
    }
  }
}
