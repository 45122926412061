import React, { Component } from 'react'

export default class UploadPhotoText extends Component {
  render() {
    return (
      <div className="file-input">
        <label htmlFor="file" onClick={() => this.props.handleButtonClick(this.props.type)}>{this.props.text}</label>
        <input accept="image/*" onChange={this.props.handleFileChange} ref={this.props.inputFileRef} type="file" className="file"/>
      </div>
    )
  }
}
