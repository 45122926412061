import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default class SensContentSplash extends Component {
  render() {
    return (
      <Modal 
        show={this.props.show} 
        backdrop="static" 
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='sensModal'
      >
        <Modal.Header>
          <Modal.Title>18+ Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This profile may contain content that is only appropriate for audiences of 18 years or older.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={this.props.dismissed}>
            I am at least 18 years of age
          </Button>
          <Button variant="secondary" onClick={this.props.declined}>
            Leave Page
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
