import { httpsCallable } from 'firebase/functions'
import moment from 'moment'
import React, { Component } from 'react'
import { Card, Form, Button, Collapse, Spinner } from 'react-bootstrap'
import { functions } from '../../services/firebase'
import ErrorCard from './ErrorCard'

export default class EditSection extends Component {
  constructor(props){
    super(props)
    this.state = {
      name: "",
      error: "",
      loading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(form){
    form.preventDefault()
    this.setState({
      loading: true
    })
    const date = moment().format("MM-DD-YYYY hh:mm:ss")
    const sectionInfo = {
      dateAdded: date,
      info: this.props.element.info,
      name: this.state.name,
      type: "section",
      uid: this.props.element.uid
    }

    const editSectionFunction = httpsCallable(functions, "editSection")
    editSectionFunction({edited: sectionInfo})
    .then(() => {
      this.setState({
        loading: false
      })
      this.props.onClose()
      this.props.showModal("Edited Section", "success")
    })
    .catch(error => {
      this.setState({
        error: error.message,
        loading: false
      })
    })
  }

  updateName(val){
    this.setState({
      name: val.target.value
    })
  }

  componentDidMount(){
    this.setState({
      name: this.props.element.name
    })
  }

  render() {
    return (
      <Collapse in={this.props.shouldRender}>
        <Card border="dark" className="mt-3 EditSectionContainer">
          <Card.Header>
            Edit
          </Card.Header>
          <Card.Body>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group className="mb-3" controlId="name">
                <Form.Text id="passwordHelpBlock" muted>
                  Name
                </Form.Text>
                <Form.Control 
                  required
                  type="name" 
                  onChange={(val) => this.updateName(val)} 
                  value={this.state.name} 
                  placeholder={this.state.name} 
                />
              </Form.Group>
              <ErrorCard error={this.state.error}/>
              <div className="buttonContainer">
                <Button type="submit" variant="custom_primary" style={{minWidth: "70px"}}>
                  {this.state.loading ?
                    <Spinner animation="border" size="sm" />
                  :
                    <>
                      Save
                    </>
                  }
                </Button>
                <Button onClick={this.props.onClose} variant="custom_red">
                  Cancel
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Collapse>
    )
  }
}
