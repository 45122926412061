import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { doc, updateDoc } from 'firebase/firestore'
import { cloneDeep } from 'lodash'
import React, { Component } from 'react'
import { Card, Collapse, Button } from 'react-bootstrap'
import { db } from '../../services/firebase'
import EditSubSectionCard from './EditSubSectionCard'
import { faEllipsisH } from '@fortawesome/fontawesome-free-solid'


export default class SubSectionCardIndividual extends Component {
  constructor(props) {
    super(props)
    this.state = {
      edit: false,
    }

    this.deleteSubSection = this.deleteSubSection.bind(this)
  }

  deleteSubSection(element) {
    let profileObject = cloneDeep(this.props.userObject)
    let sectionIndex = profileObject.info.sections.map(x => {
      return x.uid
    }).indexOf(this.props.section.uid)

    let subSectionIndex = profileObject.info.sections[sectionIndex].info.map(x => {
      return x.uid
    }).indexOf(this.props.sectionInfo.uid)
    
    profileObject.info.sections[sectionIndex].info.splice(subSectionIndex, 1)
    updateDoc(doc(db, "profiles", this.props.userObject.uid), profileObject)
    .then(() => {
      this.props.showModal("Deleted Subsection", "trash")
    })
  }

  render() {
    return (
      <>
        <div className="subSectionContainer" >
          <Card className="subSectionInfo" border="custom_primary">
            <Card.Body>
              <Collapse in={!this.state.edit}>
                {this.props.public ?
                  <a href={this.props.sectionInfo.link}>
                    <div>
                      <div className="linkContainerText">
                        <h5 className="subSectionHeader">
                          {this.props.sectionInfo.name}
                        </h5>
                      </div>
                      <div className="sectionInfoContainer">
                        {this.props.sectionInfo.description &&
                          <span>
                            {this.props.sectionInfo.description} 
                          </span>
                        }
                      </div>
                    </div>
                  </a>
                  :
                    <div>
                      <div className="linkContainerText">
                        <h5 className="subSectionHeader">
                          {this.props.sectionInfo.name}
                        </h5>
                        <span className="editIcon">
                          <Button variant="" onClick={() => this.setState({edit: true})}>
                            <FontAwesomeIcon icon={faEllipsisH} />
                          </Button>
                        </span>
                      </div>
                      <div className="sectionInfoContainer">
                        {this.props.sectionInfo.description &&
                          <span>
                            {this.props.sectionInfo.description} 
                          </span>
                        }
                      </div>
                    </div>
                }
              </Collapse>

              <EditSubSectionCard 
                shouldRender={this.state.edit} 
                section={this.props.section} 
                element={this.props.sectionInfo} 
                userObject={this.props.userObject}
                onClose={() => this.setState({edit: false})}
                onDelete={() => this.deleteSubSection(this.props.sectionInfo)}
                showModal={this.props.showModal}
              />
            </Card.Body>
          </Card>
        </div>
      </>
    )
  }
}
