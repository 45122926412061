import React, { Component } from 'react'
import { Card, InputGroup,FloatingLabel, Button, Dropdown, DropdownButton, Form, Spinner } from 'react-bootstrap'
import { social_media_list } from '../../constants/brand_constants'
import { functions } from '../../services/firebase'
import moment from "moment"
import {uid} from 'uid'
import EditSocialMediaCard from './EditSocialMediaCard'
import ErrorCard from "./ErrorCard"
import { httpsCallable } from 'firebase/functions'

export default class AddSocialMediaCard extends Component {
  constructor(props){
    super(props)
    this.state = {
      socialMedia: "" ,
      socialMediaSelected: "Social Media",
      link: "",
      error: "",
      loading: false
    }

    this.updateLinks = this.updateLinks.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  updateLinks(form){
    form.preventDefault()
    this.setState({
      loading: true
    })
    const date = moment().format("MM-DD-YYYY hh:mm:ss")
    const linkObject = {
      value: this.state.socialMedia,
      name: this.state.socialMediaSelected,
      link: form.target.link.value,
      dateAdded: date,
      uid: uid()
    }

    const addSocialMediaFunction = httpsCallable(functions, "addSocialMedia")
    addSocialMediaFunction({added: linkObject})
    .then(() => {
      this.setState({
        socialMedia: "" ,
        socialMediaSelected: "Social Media",
        link: "",
        error: "",
        loading: false
      })
      form.target.link.value = ""
    })
    .catch(error => {
      this.setState({
        error: error.message,
        loading: false
      })
    })
  }

  handleSelect(e) {
    let name = ""
    
    social_media_list.forEach(entry => {
      if (entry.value === e){
        name = entry.name
      }
    })

    this.setState({
      socialMedia: e,
      socialMediaSelected: name
    })
  }

  render() {
    return (
      <>
        <Card className="addSection">
          <Card.Header>
            <strong>
              Add/Edit Social Media
            </strong>
          </Card.Header>
          <Form onSubmit={this.updateLinks}>
            <Card.Body>
              <Form.Group>
                <InputGroup className="mb-3 dropdownButton">
                  <DropdownButton
                    variant="outline-secondary"
                    title={this.state.socialMediaSelected}
                    id="socialMedia"
                    name="socialMedia"
                    aria-label="socialMedia"
                    onSelect={this.handleSelect}
                    required
                    >
                    {social_media_list.map(socialMedia => {
                      return (
                        <Dropdown.Item 
                          key={socialMedia.value} 
                          eventKey={socialMedia.value}
                        >
                          {socialMedia.name}
                        </Dropdown.Item>
                      )
                    })}
                  </DropdownButton>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <FloatingLabel className="mb-2" controlId="floatingLink" label="Link">
                  <Form.Control required name="link" type="link" placeholder="Link" aria-describedby="linkHelpBlock" autoComplete="on"/>
                </FloatingLabel>
              </Form.Group>
              <ErrorCard error={this.state.error}/>
              <div className="buttonContainer">
                <Button type="submit" variant="custom_primary" style={{minWidth: "70px"}}>
                  {this.state.loading ?
                    <Spinner animation='border' size="sm" />
                  :
                    <>
                      Save
                    </>
                  }
                </Button>
                <Button onClick={this.props.onCancel} variant="custom_red">Close</Button>
              </div>
            </Card.Body>
          </Form>
        </Card>
        <EditSocialMediaCard userObject={this.props.userObject} showModal={this.props.showModal}/>
      </>
    )
  }
}
