import React, { Component } from 'react'
import EditSocialMediaCardIndividual from './EditSocialMediaCardIndividual'

export default class EditSocialMediaCard extends Component {
  render() {
    return (
      <>
        {this.props.userObject.info.hasOwnProperty("socialMedia") &&
          <div className="editSocialMediaLinkContainer">
            {this.props.userObject.info.socialMedia.map((socialMediaLink) => {
              return <EditSocialMediaCardIndividual key={socialMediaLink.uid} socialMediaLink={socialMediaLink} userObject={this.props.userObject} showModal={this.props.showModal}/>
            })}
          </div>
        }
      </>
    )
  }
}
