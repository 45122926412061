import React, { Component } from 'react'

export default class AlertBanner extends Component {
  getTypeClass(type){
    switch(type){
      default:
      case "update":
        return "alertBannerContainer  alert_orange"

      case "verifyEmail":
        return "alertBannerContainer alert_yellow"
    }
  }

  render() {
    if(this.props.bannerType === "announcement"){
      if(this.props.announcements){
        if(this.props.announcements.display){
          return (
            <div className={this.getTypeClass(this.props.type)}>
              <div className="message">
                  <h5>{this.props.announcements.title}</h5>
                {this.props.announcements.description}
                
                <div className="action">
                  <a href={this.props.announcements.link}>{this.props.announcements.linkText}</a>
                </div>
                
              </div>
            </div>
          )
        } 
        else {
          return null
        }
      }
    }

    else if(!this.props.show){
      return (
        <>
        </>
      )
    }

    return (
      <div className={this.getTypeClass(this.props.type)}>
        <div className="message">
          {this.props.title &&
            <h5>{this.props.title}</h5>
          }
          {this.props.message}
          
          {this.props.action&&
            <div onClick={this.props.action} className="action">
              {this.props.actionText}
            </div>
          }
        </div>
      </div>
    )
  }
}
