import React, { Component } from 'react'
import { ProgressBar, Modal, Spinner } from 'react-bootstrap'

export default class UploadModal extends Component {
  render() {
    return (
      <Modal centered className="uploadModalCard" bg="dark" show={this.props.shouldRender || this.props.loading}>
        <Modal.Header>
          {this.props.loading ? 
            <div className="uploadTitle">
              <strong>
                Compressing...
              </strong>
            </div>
          :
            <div className="uploadTitle">
              <strong>
                {this.props.title}
              </strong>
            </div>
          }
        </Modal.Header>
        <Modal.Body>
          {this.props.loading ? 
            <div className="center-spinner">
              <Spinner animation="border"/>
            </div>
          :
            <>
              <div className="uploadingText">
                Uploading {Math.round(this.props.progress)}%
              </div>
              <div>
                <ProgressBar animated now={this.props.progress} variant="success"/>
              </div>
            </>
          }
          
        </Modal.Body>
      </Modal>
    )
  }
}
