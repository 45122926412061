import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addDoc, collection } from 'firebase/firestore'
import React, { Component } from 'react'
import { Button, Form, FloatingLabel, Container } from 'react-bootstrap'
import { analytics_terms, errorMessages } from '../constants/brand_constants'
import { analytics, db } from '../services/firebase'
import "../styles/feedbackScreen.scss"
import { faCheckCircle } from '@fortawesome/fontawesome-free-solid'
import { logEvent } from 'firebase/analytics'

export default class FeedbackScreen extends Component {
  constructor(props){
    super(props)
    this.state = {
      error: errorMessages.noError,
      submitted: false
    }

    this.onSubmit=this.onSubmit.bind(this)
  }

  onSubmit(form){
    form.preventDefault()
    const formResults = {
      name: form.target.name.value,
      email: form.target.email.value,
      rating: form.target.rating.value,
      description: form.target.desc.value
    }
    addDoc(collection(db, "feedback"), formResults)
    .then(() => {
      this.setState({
        submitted: true
      })
      setTimeout(() => {
        this.props.navigate("/")
      }, 10000);
    })
    .catch(error => {
      logEvent(analytics, analytics_terms.failedSubmitFeedBack, {feedBackObject: formResults, error: error})
    })
  }
  render() {
    if(!this.props.authed){
      this.props.navigate("/nav/404")
      return null
    }
    return (
      <div className="mt-3">
        {this.state.submitted ? 
          <Container className="submitted">
            <h3>Thanks for your feedback!</h3>
            <FontAwesomeIcon icon={faCheckCircle} size="xl" className="check"/>
          </Container>
        :
        <>
          <h3>Provide Feedback</h3>
            <Container>
              <Form onSubmit={this.onSubmit} className="form">
                <Form.Group>
                  <FloatingLabel 
                    suggested="name" 
                    className="mb-3" 
                    controlId="floatingName" 
                    label="name">
                      <Form.Control
                        required
                        name="name" 
                        placeholder="Name" 
                        aria-label="name" 
                        aria-describedby="name"
                      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group>
                  <FloatingLabel 
                    suggested="email" 
                    className="mb-3" 
                    controlId="floatingEmail" 
                    label="email">
                      <Form.Control 
                        required
                        name="email" 
                        placeholder="email" 
                        aria-label="email" 
                        aria-describedby="email"
                      />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group>
                  <div>
                    How would you rate your experience?
                  </div>
                  <Form.Select required aria-label="select" name="rating" defaultValue={5}>
                    <option value="1">1 - Worst</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5 - Best</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="descContainer">
                  <Form.Label>Any Specific feedback you would like use to know about?</Form.Label>
                  <Form.Control
                    required
                    name="desc" 
                    placeholder="Description" 
                    aria-label="desc" 
                    aria-describedby="desc"
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
                <Button variant='custom_primary' type={'submit'}>
                  Submit Feedback
                </Button>
              </Form>
            </Container>
          </>
        }
      </div>
    )
  }
}
