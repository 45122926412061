import moment from 'moment'
import React, { Component } from 'react'
import { Card, Form, Button, Collapse } from 'react-bootstrap'
import { functions } from '../../services/firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/fontawesome-free-solid'
import { httpsCallable } from 'firebase/functions'
import ErrorCard from './ErrorCard'

export default class EditSubSectionCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: "",
      name: "",
      link: "",
      description: ""
    }

    this.editLinks = this.editLinks.bind(this)
  }

  componentDidMount(){
    this.setState({
      name: this.props.element.name,
      link: this.props.element.link,
      description: this.props.element.description
    })
  }

  editLinks(form) {
    form.preventDefault()
    const date = moment().format("MM-DD-YYYY hh:mm:ss")
    const subSectionObject = {
      dateAdded: date,
      description: this.state.description,
      link: this.state.link,
      name: this.state.name,
      uid: this.props.element.uid
    }

    const editSectionInfoFunction = httpsCallable(functions, "editSectionInfo")
    editSectionInfoFunction({edited: subSectionObject, section: this.props.section, element: this.props.element})
    .then(() => {
      this.props.onClose()
      this.props.showModal("Edited Subsection", "success")
    })
    .catch(error => {
      this.setState({
        error: error.message
      })
    })
  }

  render() {
    return (
      <Collapse in={this.props.shouldRender}>
        <Card border="dark" className="mt-3 EditSectionContainer">
          <Card.Header>
            Edit
          </Card.Header>
          <Card.Body>
            <Form onSubmit={this.editLinks}>
              <Form.Group className="mb-2" controlId="name">
                <Form.Text muted>
                  Title
                </Form.Text>
                <Form.Control
                  required
                  type="name"
                  onChange={(v) => this.setState({name: v.target.value})}
                  value={this.state.name}
                  placeholder={"Title"}
                />
              </Form.Group>

              <Form.Group className="mb-2" controlId="description">
                <Form.Text muted>
                  Description
                </Form.Text>
                <Form.Control
                  required
                  type="description"
                  onChange={(v) => this.setState({description: v.target.value})}
                  value={this.state.description}
                  placeholder='Description'
                  as="textarea"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="link">
                <Form.Text muted>
                  Link
                </Form.Text>
                <Form.Control
                  required
                  type="link"
                  onChange={(v) => this.setState({link: v.target.value})}
                  value={this.state.link}
                  placeholder='Link'
                />
              </Form.Group>
              <ErrorCard error={this.state.error}/>
              <div className="buttonContainer">
                <Button type="submit" variant="custom_primary"> 
                  Save
                </Button>
                <Button onClick={this.props.onClose} variant="custom_red">
                  Cancel
                </Button>
                <Button variant={"custom_red"} onClick={this.props.onDelete}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Collapse>
    )
  }
}
