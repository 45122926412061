import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default class ConfirmModal extends Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.props.message}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={this.props.confirm}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={this.props.cancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
