import React, { Component } from 'react'
import { Card, InputGroup, FormControl, Button, Form, Collapse, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont, faInfoCircle, faLink } from '@fortawesome/fontawesome-free-solid'
import { functions } from '../../services/firebase'
import { uid } from 'uid'
import moment from 'moment'
import ErrorCard from './ErrorCard'
import { httpsCallable } from 'firebase/functions'

export default class AddSectionInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: "",
      loading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(form) {
    form.preventDefault()
    this.setState({
      loading: true
    })
    const date = moment().format("MM-DD-YYYY hh:mm:ss")
    const sectionInfoObject = {
      name: form.target.name.value,
      description: form.target.description.value,
      link: form.target.link.value,
      dateAdded: date,
      uid: uid()
    }

    const addSectionInfoFunction = httpsCallable(functions, "addSectionInfo")
    addSectionInfoFunction({added: sectionInfoObject, sectionUID: this.props.section.uid})
    .then(() => {
      this.setState({
        loading: false
      })
      this.props.onCancel()
      this.props.showModal("Added Subsection", "success")
      form.target.name.value = ""
      form.target.description.value = ""
      form.target.link.value = ""
    })
    .catch(error => {
      this.setState({
        error: error.message,
        loading: false
      })
    })
  }
  render() {
    return (
      <Collapse in={this.props.shouldRender}>
        <Card className="addSection" border="primary">
          <Card.Header>Add sub-section info</Card.Header>
          <Form onSubmit={this.handleSubmit}>
            <Card.Body>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faFont} />
                </InputGroup.Text>
                <FormControl required name="name" placeholder="Sub-section Title" aria-label="Sub-section Title" aria-describedby="basic-addon1" />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </InputGroup.Text>
                <FormControl name="description" placeholder="Description" as="textarea" aria-label="info" />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faLink} />
                </InputGroup.Text>
                <FormControl required name="link" placeholder="Purchase Link" aria-label="Purchase Link" aria-describedby="basic-addon1" />
              </InputGroup>
              <ErrorCard error={this.state.error} />
              <div className="buttonContainer">
              <Button type="submit" variant="custom_primary" style={{minWidth: "70px"}}>
                {this.state.loading ?
                  <>
                    <Spinner animation="border" size="sm"/>
                  </>
                :
                  <>
                    Save
                  </>
                }
              </Button>
                <Button onClick={this.props.onCancel} variant="custom_red">Close</Button>
              </div>
            </Card.Body>
          </Form>
        </Card>
      </Collapse>
    )
  }
}
