// this will display specific information regarding the profile
import React from 'react'
import { useLocation } from 'react-router-dom'
import ConfirmEmailContainerScreen from './ConfirmEmailContainerScreen';
import NotFoundScreen from './NotFoundScreen';
import PasswordResetContainerScreen from './PasswordResetContainerScreen';


export default function FirebaseActionHandler(props) {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  // queryParams is an object with a .get() method...
  const mode = queryParams.get('mode');
  const code = queryParams.get('oobCode')
  const apiKey = queryParams.get('apiKey')
  const lang = queryParams.get('lang')
  // const code = queryParams.get('oobCode')
  // const apiKey = queryParams.get('apiKey')
  // const lang = queryParams.get('lang')

  if(mode === "resetPassword"){
    return (
      <PasswordResetContainerScreen 
        mode={mode} 
        code={code} 
        apiKey={apiKey} 
        lang={lang} 
        navigate={path => props.navigate(path)}
        currentUser={props.currentUser}
      />
    )
  }

  else if(mode==="verifyEmail") {
    return (
      <ConfirmEmailContainerScreen 
        mode={mode}
        code={code}
        apiKey={apiKey}
        lang={lang}
        navigate={path => props.navigate(path)}
        currentUser={props.currentUser}
      />
    )
  }

  else {
    return (
      <NotFoundScreen authed={false} navigate={path => this.props.navigation(path)} />
    )
  }
}

