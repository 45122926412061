import React, { Component } from 'react'
import ProfileImage from './components/ProfileImage'
import '../styles/profileSettingsScreen.scss'
import ToggleSettingsField from './components/ToggleSettingsField'
import TextInputSettingsField from './components/TextInputSettingsField'
import SettingsButton from './components/SettingsButton'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import ConfirmModal from './components/ConfirmModal'
import { functions } from '../services/firebase'
import { httpsCallable } from 'firebase/functions'

export default class ProfileSettingsScreen extends Component {
  constructor(props){
    super(props)
    this.state = {
      showModal: false,
      deleting: false
    }

    this.resetPasswordClick = this.resetPasswordClick.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.deleteAccount = this.deleteAccount.bind(this)
  }

  resetPasswordClick(){
    const auth = getAuth();
    sendPasswordResetEmail(auth, this.props.profileObject.email)
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage)
    });
  }

  confirmDelete(){
    this.setState({
      showModal: true
    })
  }

  deleteAccount(){
    const deleteUserFunction = httpsCallable(functions, "deleteUser")
    deleteUserFunction()
    .then(() => {
      this.props.logout()
    })
    .catch(error => {
      console.log("first", error)
    })
  }

  render() {
    if (this.state.deleting){
      return (
        <>
        </>
      )
    }

    return (
      <div className="profileSettingsScreenContainer">
        <title>Profile Settings | LNKABLE</title>

        <ConfirmModal 
          show={this.state.showModal}
          title="Confirm Delete Account"
          message="Deleting your account is permanent and cannot be reversed"
          confirm={this.deleteAccount}
          cancel={() => this.setState({showModal: false})}
        />

        {this.props.profileObject &&
          <>
            <div className="profileInformationContainer">
              <ProfileImage imagePath={this.props.profileObject.avatarPicture} />
              <div className="userName">
                @{this.props.profileObject.userName}
              </div>
              <div className="name">
                {this.props.profileObject.name}
              </div>
              <div>
                {this.props.profileObject.bio}
              </div>
            </div>

            <div className="profileSettingsContainer">
              {/* Header */}
              <div className="settingsTitle">
                Profile Settings
              </div>

              {/* Change Name */}
              <TextInputSettingsField 
                text="Change Name" 
                placeholder={this.props.profileObject.name} 
                value={this.props.profileObject.name}
                private={false}
                databaseName={"name"}
                profileObject={this.props.profileObject}
              />

              {/* change user name*/}
              <TextInputSettingsField 
                text="Change Username" 
                placeholder={this.props.profileObject.userName} 
                value={this.props.profileObject.userName}
                private={false}
                databaseName={"userName"}
                profileObject={this.props.profileObject}
              />

              {/* Change Bio */}
              <TextInputSettingsField 
                text="Change Bio" 
                placeholder={this.props.profileObject.bio} 
                value={this.props.profileObject.bio}
                private={false}
                databaseName={"bio"}
                profileObject={this.props.profileObject}
              />

              {/* unsplash */}
              <ToggleSettingsField 
                text="Enable random Photo from unsplash" 
                note={"This every time your page is loaded, a new cover photo will appear!"}
                value={this.props.profileObject.randomUnsplash}
                private={false}
                databaseName={"randomUnsplash"}
                profileObject={this.props.profileObject}
              />

              {/* two factor */}
              <ToggleSettingsField 
                text="Enable Two Factor Authentication (Coming soon)"
                value={this.props.privateSettings.twoFactor}
                private={true}
                databaseName={"twoFactor"}
                profileObject={this.props.profileObject}
                privateSettings={this.props.privateSettings}
              />

              {/* sensitive information */}
              <ToggleSettingsField 
                text="18+ Content"
                note="Does you page or links contain 18+ Content?"
                value={this.props.profileObject.sensInformation}
                private={false}
                databaseName={"sensInformation"}
                profileObject={this.props.profileObject}
              />

              {/* unsplash search term */}
              <TextInputSettingsField 
                text="Unsplash Search Term" 
                placeholder={"Landscape"} 
                note="You can narrow down the random cover photo using a custom search term" 
                value={this.props.profileObject.unsplashSearchTerm}
                private={false}
                databaseName={"unsplashSearchTerm"}
                profileObject={this.props.profileObject}
              />

              {/* Reset Password */}
              <SettingsButton 
                text="Reset Password" 
                note={"An email will be sent to " + this.props.profileObject.email}
                variant="custom_primary"
                onClick={this.resetPasswordClick}
              />
              
              {/* Delete Account */}
              <SettingsButton 
                text="Delete Account" 
                note="This is non-reversible and permanent"
                variant="custom_red"
                onClick={this.confirmDelete}
              />
            </div>
          </>
        }
      </div>
    )
  }
}
