import React, { Component } from 'react'
import { Card, InputGroup, FloatingLabel, Button, Form, DropdownButton, Dropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/fontawesome-free-solid'
import { faTrash } from '@fortawesome/fontawesome-free-solid';
import { social_media_list } from '../../constants/brand_constants';
import { errorMessages } from '../../constants/brand_constants';
import { functions } from '../../services/firebase';
import moment from 'moment';
import ErrorCard from './ErrorCard';
import { httpsCallable } from 'firebase/functions';

export default class EditSocialMediaCardIndividual extends Component {
  constructor(props){
    super(props)
    this.state = {
      socialMediaDropdown: "",
      error: errorMessages.noError,
      loading: false,
      deleteLoading: false
    }

    this.editLinks = this.editLinks.bind(this)
    this.deleteLink = this.deleteLink.bind(this);
    this.dropdownChange = this.dropdownChange.bind(this)
  }

  componentDidMount(){
    this.setState({
      socialMediaDropdown: this.props.socialMediaLink.value
    })
  }

  getName(value){
    let name = ""
    social_media_list.forEach(media => {
      if(media.value === value){
        name = media.value
      }
    })
    return name
  }

  dropdownChange(dropdownValue) {
    this.setState({
      socialMediaDropdown: dropdownValue
    })
  }

  editLinks(form, uid){
    form.preventDefault()
    this.setState({
      loading: true
    })
    const date = moment().format("MM-DD-YYYY hh:mm:ss")
    const editedObject = {
      dateAdded: date,
      link: form.target.link.value,
      name: this.getName(form.target.socialMedia.value),
      uid: uid,
      value: this.state.socialMediaDropdown
    }

    const editSocialMediaFunction = httpsCallable(functions, "editSocialMedia")
    editSocialMediaFunction({edited: editedObject})
    .then(() => {
      this.setState({
        error: "",
        loading: false
      })
      this.props.showModal("Added Social Media", "success")
    })
    .catch(error => {
      this.setState({
        error: error.message,
        loading: false
      })
    })
  }

  deleteLink(uid){
    this.setState({
      deleteLoading: true
    })
    const deleteSocialMedia = httpsCallable(functions, "deleteSocialMedia")
    deleteSocialMedia({uid: uid})
    .then(() =>{
      this.setState({
        deleteLoading: false
      })
      this.props.showModal("Deleted Social Media", "trash")
    })
    .catch(error => {
      this.setState({
        error: error.message,
        deleteLoading: false
      })
    })
  }


  render() {
    return (
      <Card 
        className="addSection" 
        key={this.props.socialMediaLink.uid} 
        border="custom_light_gray"
      >
        <Form className="editSocialMediaForm" onSubmit={(form) => this.editLinks(form, this.props.socialMediaLink.uid)}>
          <Card.Body>
          <div className="mb-2">
            <FontAwesomeIcon style={{paddingRight: "10px"}} icon={faPencilAlt} />
            <strong>
              Edit social media link
            </strong>
          </div>
            <Form.Group>
              <InputGroup className="mb-2 dropdownButton">
                <DropdownButton
                  variant='outline-secondary'
                  title={this.state.socialMediaDropdown}
                  id="socialMedia"
                  name="socialMedia"
                  aria-label="socialMedia"
                  onSelect={this.dropdownChange}
                >
                  {social_media_list.map(socialMedia => {
                    return (
                      <Dropdown.Item  
                        key={socialMedia.value}
                        eventKey={socialMedia.value}
                      >
                        {socialMedia.name}
                      </Dropdown.Item>
                    )
                  })}
                </DropdownButton>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <FloatingLabel className="mb-2" controlId="floatingLink" label="Link">
                <Form.Control required defaultValue={this.props.socialMediaLink.link} name="link" type="link" placeholder="Link" aria-describedby="linkHelpBlock" autoComplete="on"/>
              </FloatingLabel>
            </Form.Group>
            <ErrorCard error={this.state.error}/>
            <div className="buttonContainer">
              <Button type="submit" variant="custom_primary" style={{minWidth: "70px"}}>
                {this.state.loading ?
                  <Spinner animation="border" size="sm" />
                :
                  <>
                    Edit
                  </>
                }
              </Button>
              <Button variant="custom_red" onClick={() => this.deleteLink(this.props.socialMediaLink.uid)} style={{minWidth: "70px"}}>
                {this.state.deleteLoading ?
                  <Spinner animation="border" size="sm" />
                :
                  <FontAwesomeIcon icon={faTrash} style={{color: "white"}}/>
                }
              </Button>
            </div>
          </Card.Body>
        </Form>
      </Card>
    )
  
  }
}
