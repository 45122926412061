// this will be displayed when someone clicks a profiles link "/profile/USERNAME"
// Will Display constant information
import React, { Component, createRef } from 'react'
import ProfileImage from './components/ProfileImage'
import CoverPhoto from './components/CoverPhoto'
import SocialMediaList from './components/SocialMediaList'
import LinkList from './components/LinkList'
import '../styles/profileScreens.scss'
import { doc, updateDoc } from 'firebase/firestore'
import {  db } from '../services/firebase'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faShare } from '@fortawesome/fontawesome-free-solid'
import { storage } from '../services/firebase'
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage'
import UploadPhotoText from './components/UploadPhotoText'
import { cloneDeep } from 'lodash'
import UploadModal from './components/UploadModal'
import ToastNote from './components/ToastNote'
import AlertBanner from './components/AlertBanner'
import { alertMessages, analytics_terms, brand_constants, coverPhoto_regex, profilePicture_regex } from '../constants/brand_constants'
import { sendEmailVerification } from 'firebase/auth'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../services/firebase'
import { compress } from '../services/compression'

export default class ProfileScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: "",
      progress: 0,
      loading: false,
      uploadModelOpen: false,
      uploadModalTitle: "",
      toastContent: "",
      toastTitle: "",
      showToast: false,
      toastType: "",
      displayModal: false,
      modalMessage: "",
      modalVariant: ""
    }
    this.inputFileRef_profile_picture = createRef();
    this.inputFileRef_cover_photo = createRef()
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.closeToast = this.closeToast.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
    this.copyText = this.copyText.bind(this);
  }

  handleButtonClick(type) {
    this.setState({
      type: type
    })
    if(type === "profile") {
      this.inputFileRef_profile_picture.current.click()
    }
    else if (type === "cover") {
      this.inputFileRef_cover_photo.current.click()
    }
  }

  // returns bool
  // TODO: Will this need a function?
  async deleteOldPhoto(type){
    let strippedName = ""
    if(type === "profile"){
      strippedName =  this.props.profileObject.avatarPicture ? this.props.profileObject.avatarPicture.match(profilePicture_regex) : null
      if(this.props.profileObject.avatarPicture === null ){
        return true
      }
    }
    else if (type === "cover"){
      strippedName = this.props.profileObject.coverPhoto ? this.props.profileObject.coverPhoto.match(coverPhoto_regex) : null
      if(this.props.profileObject.coverPhoto === null ){
        return true
      }
    }

    const path = this.props.profileObject.uid + "/" + strippedName
    const imageRef = ref(storage, path)

    return deleteObject(imageRef)
      .then(() => {
        return true
      })
      .catch((error) => {
        logEvent(analytics, analytics_terms.imageDeleteFailed, {path: path, error: error})
        return false
      })
  }

  // TODO: will this need function in firebase?
  handleFileChange(e) {
    if (e.target.files.length) {
      const fileName = e.target.files[0].name
      const extension = fileName.substring(fileName.lastIndexOf('.')+1).toLowerCase();

      if(e.target.files[0].size/1000 < 5000) {
        let prefix = ""
        if(this.state.type === "profile"){
          prefix = "profilePicture"
        } 
        else if (this.state.type === "cover") {
          prefix = "coverPhoto"
        } 

        // invalid type
        else {
          logEvent(analytics, analytics_terms.invalidType, {type: this.state.type})
          return
        }

        const imageRef = ref(storage, `${this.props.profileObject.uid}/${prefix}.${extension}`)
        this.setState({
          loading: true,
          uploadModelOpen: true
        })
        compress(e.target.files[0])
          .then(compressedImage => {
            if (compressedImage === null){
              this.setState({
                showToast: true,
                toastContent: `There was an error uploading your image`,
                toastTitle: `Image Upload`,
                toastType: "danger",
                loading: false,
                uploadModelOpen: false
              })
              return
            }

            // done compressing
            this.setState({
              loading: false,
              uploadModelOpen: false,
            })

            // begin uploading task
            const uploadTask = uploadBytesResumable(imageRef, compressedImage)
            this.deleteOldPhoto(this.state.type)
            .then((status) => {
              if(status){
                uploadTask.on("state_changed", snapshot => {
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  this.setState({
                    uploadModelOpen: true,
                    progress: progress,
                    uploadModalTitle: "Uploading profile picture",
                  })
                }, error => {
                  logEvent(analytics, analytics_terms.failedImageUpload, {errorCode: error.code, message: error.message})
                  this.setState({
                    showToast: true,
                    toastTitle: "Error",
                    toastContent: "There was an error. Please try again later",
                    toastType: "danger"
                  })
                }, () => {
                  this.setState({
                    uploadModelOpen: false,
                    progress: 0,
                    uploadModalTitle: "",
                    showToast: true,
                    toastContent: `successfully uploaded ${this.state.type} photo`,
                    toastTitle: `Uploaded Photo`,
                    toastType: "success"
                  })
                  getDownloadURL(uploadTask.snapshot.ref)
                    .then((downloadURL) => {
                      let profileObject = cloneDeep(this.props.profileObject)
                      if(this.state.type === 'cover') {
                        profileObject.coverPhoto = downloadURL
                        updateDoc(doc(db, "profiles", this.props.profileObject.uid), profileObject)
                      }
                      else if (this.state.type === 'profile') {
                        profileObject.avatarPicture = downloadURL
                        updateDoc(doc(db, "profiles", this.props.profileObject.uid), profileObject)
                      }
                    })
                })
              } else {
                logEvent(analytics, analytics_terms.deleteOldPhotoFail, {userObject: this.props.userObject})
              }
            })
          })
      } 
      
      // Invalid picture
      else {
        this.setState({
          showToast: true,
          toastTitle: "Invalid Picture",
          toastContent: "The selected photo is too big. Please make sure your photo is less than 5mb",
          toastType: "danger"
        })
      }
    }
    // Can use else to handle no photo detected here
  }

  // TODO: WIll this need a function?
  deletePhoto(type){
    let temp = cloneDeep(this.props.profileObject)
    if (type === "profile") {
      temp.avatarPicture = null
    }

    else if (type === "cover") {
      temp.coverPhoto = null
    }

    updateDoc(doc(db, "profiles", this.props.profileObject.uid), temp)
    this.deleteOldPhoto(type)
  }

  closeToast() {
    this.setState({
      showToast: false
    })
    setTimeout(() => {
      this.setState({
        toastTitle: "",
        toastContent: "",
        toastType: ""
      })
      
    }, 1000);
  }

  resendEmail(){
    sendEmailVerification(this.props.currentUser)
    .then(() => {
      this.setState({
        showToast: true,
        toastTitle: "Email Verification",
        toastContent: "An email has been sent to verify your email.",
        toastType: "success"
      })
    })
    .catch(error => {
      logEvent(analytics, analytics_terms.failedEmailVerification, {errorCode: error.code, message: error.message})
    })
  }

  copyText(){
    const link = `${brand_constants.copy_link}${this.props.profileObject.userName}`
    navigator.clipboard.writeText(link);
    this.setState({
      showToast: true,
      toastTitle: "Sharing",
      toastContent: "Sharable link has been copied to your clipboard",
      toastType: "success"
    })
  }

  componentDidUpdate(){
    // if profile is not authed, send to home screen
    if(!this.props.authed){
      this.props.navigate("/")
    }
  }

  render() {
    // if user is not authed, have blank screen
    if(!this.props.authed){
      return null
    }

    else if(this.props.profileObject){
      return (
        <div className="profileScreenContainer">
          <title>Profile | LNKABLE</title>
          <UploadModal
            progress={this.state.progress}
            loading={this.state.loading}
            title={this.state.uploadModalTitle} 
            shouldRender={this.state.uploadModelOpen}
          />
          {this.props.authed &&
            <AlertBanner
              show={!this.props.currentUser.emailVerified}
              message={alertMessages.confirmEmail}
              action={this.resendEmail}
              actionText={alertMessages.confirmEmailAction}
              type={"verifyEmail"}
            />
          }
          {this.props.announcements&&
            <AlertBanner
              bannerType="announcement"
              announcements={this.props.announcements}
            />
          }
          <CoverPhoto 
            imagePath={this.props.profileObject.coverPhoto} 
            unsplash={this.props.profileObject.randomUnsplash}
            unsplashTerm={this.props.profileObject.unsplashSearchTerm}
          />
          <ProfileImage 
            imagePath={this.props.profileObject.avatarPicture} 
          />
          <ToastNote
            variant={this.state.toastType}
            show={this.state.showToast} 
            onClose={this.closeToast} 
            title={this.state.toastTitle} 
            content={this.state.toastContent}
          />
          <div className="profileContentsContainer">
            <div className="profilePageContentContainer">
              <span className="profileButtonContainer">
                <div className="viewPublicProfile">
                  <Dropdown>
                    <Dropdown.Toggle
                      className="first-bg-hover white-text" 
                      variant="custom"
                    >
                      <FontAwesomeIcon icon={faShare} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shareContainer">
                      <Dropdown.Item onClick={() => {this.props.navigate(`/${this.props.profileObject.userName}`)}}>View Profile</Dropdown.Item>
                      <Dropdown.Item onClick={this.copyText}>Share Profile</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="editProfileContainer">
                  <Dropdown>
                    <Dropdown.Toggle 
                      className="first-bg-hover white-text" 
                      variant="custom"
                    >
                      <FontAwesomeIcon icon={faImage} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <UploadPhotoText 
                        text={'Upload Profile Picture'} 
                        type={"profile"} 
                        handleButtonClick={(type) => this.handleButtonClick(type)} 
                        handleFileChange={this.handleFileChange} 
                        inputFileRef={this.inputFileRef_profile_picture} 
                      /> 
                      <Dropdown.Item>  
                        <div className="file-input red-text" onClick={() => this.deletePhoto("profile")}>
                          <label>
                            Remove Profile Picture
                          </label>
                        </div>
                      </Dropdown.Item>
                      <UploadPhotoText 
                        text={'Upload Cover Photo'} 
                        type={"cover"} 
                        handleButtonClick={(type) => this.handleButtonClick(type)} 
                        handleFileChange={this.handleFileChange} 
                        inputFileRef={this.inputFileRef_cover_photo} 
                      />
                      <Dropdown.Item>
                        <div className="file-input red-text" onClick={() => this.deletePhoto("cover")}>
                          <label>
                            Remove Cover Photo
                          </label>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </span>
              <div className="nameContainer">
                <div className="usernameContainer">
                    @{this.props.profileObject.userName}
                </div>
                <div className="realNameContainer">
                  {this.props.profileObject.name}
                </div>
                <div>
                  {this.props.profileObject.bio}
                </div>
              </div>

              <div className="socialMediaContainer">
                <SocialMediaList 
                  links={this.props.profileObject.info} 
                  userObject={this.props.profileObject} 
                  public={false}
                />
              </div>
              <div className="infoContainer">
                <LinkList 
                  userObject={this.props.profileObject} 
                  public={false}
                  showModal={this.props.showModal}
                />
              </div>
            </div>
          </div>
        </div>
      )
    }

    else {
      return null
    }
  }
}
