import React, { Component } from 'react'
import empty_user_profile from "../../assets/empty_user_picture.jpeg"
import { Fade, Placeholder } from 'react-bootstrap'

export default class ProfileImage extends Component {
  constructor(props){
    super(props)
    this.state = {
      loaded: false,
      fade: false
    }
  }

  render() {
    if(this.props.imagePath !== null) {
      return (
        <div className="profilePictureContainer">
          {!this.state.loaded &&
            <Placeholder as="p" animation="glow" className="skel_proPic">
              <Placeholder xs={12} />
            </Placeholder> 
          }
          <Fade in={this.state.fade} timeout={1000}>
            <img 
              src={this.props.imagePath} 
              className='profilePicture' 
              alt=''
              onLoad={() => this.setState({loaded: true, fade: true})}
            />
          </Fade>
        </div>
      )
    } else {
      return (
        <div className="profilePictureContainer">
          <img src={empty_user_profile} className='profilePicture' alt=''/>
        </div>
      )
    }
  }
}
