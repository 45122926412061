import React, { Component } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck} from '@fortawesome/fontawesome-free-solid'
import { deepCopy } from '@firebase/util'
import { doc, updateDoc } from 'firebase/firestore'
import { db, functions } from '../../services/firebase'
import { errorMessages } from '../../constants/brand_constants'
import { httpsCallable } from 'firebase/functions'
/**
 * text
 * placeholder
 * value
 * private
 * databaseName
 */
export default class TextInputSettingsField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      textVal: "",
      error: errorMessages.noError,
      loading: false
    }
    this.updateDatabase = this.updateDatabase.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    this.setState({
      textVal: this.props.value,
      error: errorMessages.noError
    })
  }

  updateDatabase(form){
    form.preventDefault()
    this.setState({
      loading: true
    })

    if(!this.props.canBeEmpty){
      if(this.state.textVal === "") {
        this.setState({
          error: errorMessages.emptyField,
          loading: false
        })
        return
      }
    }

    // check if the value has actually changed
    if(this.state.textVal !== this.props.value){
      // Non-private settings
      if(!this.props.private) {
        if(this.props.databaseName === "userName"){
          const updateUserNameFunction = httpsCallable(functions, "changeUserName")
          updateUserNameFunction({newUser: this.state.textVal})
          .then(() => {
            this.setState({
              loading: false
            })
          })
          .catch(error => {
            this.setState({
              error: error.message,
              loading: false
            })
          })

        } 
        
        else {
          let profileObject = deepCopy(this.props.profileObject)
          profileObject[this.props.databaseName] = this.state.textVal
          updateDoc(doc(db, "profiles", this.props.profileObject.uid), profileObject)
          .then(() => {
            this.setState({
              loading: false
            })
          })
        }
      }
      // TODO: private settings changes here
      // TODO: TWO FACTOR?
    }
  }

  handleChange(form){
    this.setState({
      textVal: form.target.value
    })
  }

  render() {
    return (
      <div className="textFieldContainer">
        <div className="textContainer">
          {this.props.text}
        </div>
        <div className="textInputContainer">
          <Form className="formContainer" onSubmit={this.updateDatabase}>
            <Form.Group>
              <Form.Control className="textInput" 
                placeholder={this.props.placeholder} 
                value={this.state.textVal} 
                onChange={this.handleChange}
                isInvalid={this.state.error !== errorMessages.noError}
              />

              <Form.Control.Feedback type="invalid" className="textInput errorMessage">
                {this.state.error}
              </Form.Control.Feedback>

              <Form.Text className="text-muted">
              </Form.Text>
            </Form.Group>
            <Button type="submit">
              {this.state.loading ? 
                <Spinner animation="border" size="sm" />
                :
                <FontAwesomeIcon icon={faCheck} />
              }
            </Button>
          </Form>
        </div>
      </div>
    )
  }
}
