import React, { Component } from 'react'
import { Card, InputGroup, FormControl, Button, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFont } from '@fortawesome/fontawesome-free-solid'
import moment from 'moment'
import { uid } from 'uid'
import { functions } from '../../services/firebase'
import ErrorCard from './ErrorCard'
import { httpsCallable } from 'firebase/functions'
import { Spinner } from 'react-bootstrap'

export default class AddSectionCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: "",
      loading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(form) {
    form.preventDefault()
    this.setState({
      loading: true
    })
    const date = moment().format("MM-DD-YYYY hh:mm:ss")
    const sectionObject = {
      type: "section",
      name: form.target.name.value,
      dateAdded: date,
      uid: uid(),
      info: []
    }

    const addSectionFunction = httpsCallable(functions, "addSection")
    addSectionFunction({added: sectionObject})
    .then(() => {
      this.setState({
        loading: false
      })
      form.target.name.value = ""
      this.props.onCancel()
      this.props.showModal("Added Section", "success")
    })
    .catch(error => {
      this.setState({
        loading: false,
        error: error.message,
      })
    })
  }

  render() {
    return (
      <Card className="addSection">
        <Card.Header>Add Section</Card.Header>
        <Form onSubmit={this.handleSubmit}>
          <Card.Body>
            <Form.Group>
              <Form.Text muted>
                Section Title
              </Form.Text>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faFont} />
                </InputGroup.Text>
                <FormControl required name="name" placeholder="Section Title" aria-label="Section Title" aria-describedby="basic-addon1" />
              </InputGroup>
            </Form.Group>
            <ErrorCard error={this.state.error}/>
            <div className="buttonContainer">
              <Button type="submit" variant="custom_primary" style={{minWidth: "70px"}}>
                {this.state.loading ?
                <>
                  <Spinner animation="border" size="sm"/>
                </>
              :
              <>
                Save
              </>
              }
              </Button>
              <Button onClick={this.props.onCancel} variant="custom_red">Close</Button>
            </div>
          </Card.Body>
        </Form>
      </Card>     
    )
  }
}
